import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {UserAuthData} from "./types";
import {RootState} from "../../store";
import ability, {updateAbility} from "../../utils/ability";

export interface AuthInitialState {
    user: string | null
    refreshToken: string | null
    accessToken: string | null
}

const initialState: AuthInitialState = {
    user: null,
    refreshToken: null,
    accessToken: null,
}

export const authSlice = createSlice({
    name: "auth",
    initialState: initialState,
    reducers: {
        setCredentials: (state, action: PayloadAction<UserAuthData>) => {
            const {profile, accessToken, refreshToken} = action.payload
            localStorage.setItem("user", JSON.stringify({
               ...action.payload
            }));
            updateAbility(ability, profile.roles)
            state.user = profile.email
            state.accessToken = accessToken
            state.refreshToken = refreshToken
        },
        logOut: (state) => {
            state.user = null
            state.accessToken = null
            state.refreshToken = null
            localStorage.removeItem("user");
        }
    }
})

export const {setCredentials, logOut} = authSlice.actions;

export const selectCurrentUser = (state: RootState) => state.auth.user;

export const selectRefreshToken = (state: RootState) => state.auth.refreshToken;
export const selectToken = (state: RootState) => state.auth.accessToken;
