import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ModeIcon from '@mui/icons-material/Mode';
import {
    Backdrop,
    Box,
    Button,
    ButtonGroup,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    TablePagination,
    TextField, Typography
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {TABLE_ROW_LENGTH_OPTIONS} from "../../../config/table";
import {selectCurrentCreationsFiltersState, setCreationsFilters} from "../store/creationsSlice";
import {FilterItemChip} from "../../drafts/types";
import {
    useDeleteCreationMutation,
    useGetCreationsFiltersMetaQuery,
    useGetCreationsQuery
} from "../api/creationsApiSlice";
import {useTranslation} from "react-i18next";
import {Theme, useTheme} from "@mui/material/styles";
import useDebounce from "../../../hooks/useDebounce";
import {Clear} from "@mui/icons-material";
import Chip from "@mui/material/Chip";
import {Link} from 'react-router-dom';
import {enqueueSnackbar} from "notistack";
import DialogContentText from "@mui/material/DialogContentText";
import './creationsList.scss'
import CmStatusIconBox from "../../../components/CmStatusIconBox/CmStatusIconBox";
const CreationsList = () => {
    const [searchByName, setSearchByName] = React.useState<string>('');
    const currentFiltersState = useSelector(selectCurrentCreationsFiltersState);
    const [rowLength, setRowLength] = React.useState(TABLE_ROW_LENGTH_OPTIONS[0]);
    const [selectedCreationToRemove, setSelectedCreationToRemove] = React.useState<number | null>(null);
    const [skipFetchingCreations, setSkipFetchingCreations] = React.useState(false);
    const page = currentFiltersState.page;

    let params = [`items=${rowLength.toString()}`];
    let filterItemChips: FilterItemChip[] = []
    for (const queryKey in currentFiltersState) {
        const value = currentFiltersState[queryKey as keyof typeof currentFiltersState]
        if (Array.isArray(value)) {
            value.forEach(item => {
                params.push(`q[${queryKey}][]=${item.toString()}`)
                filterItemChips.push({name: queryKey, value: item, isArray: true})
            })
        } else if (queryKey === 'page') {
            params.push(`page=${value.toString()}`)
        } else if (value) {
            params.push(`q[${queryKey}]=${value.toString()}`)
            filterItemChips.push({name: queryKey, value: value.toString(), isArray: false})
        }
    }

    const [deleteCreation, {isLoading: deletingCreation}] = useDeleteCreationMutation()


    const deleteCreationMutation = async (creationId: number) => {
        try {
            const isLastItem = creations?.length === 1;
            if (isLastItem && page > 0) {
                setSkipFetchingCreations(true);
            }
            const result = await deleteCreation(creationId).unwrap()
            if (!result?.error) {
                if (isLastItem && page > 0) {
                    setSkipFetchingCreations(false);
                    dispatch(setCreationsFilters({
                        ...currentFiltersState,
                        page: page - 1
                    }))
                }
                enqueueSnackbar('Pomyślnie usunięto kreacje', {variant: 'success'});
            }
        } catch (err: any) {
            console.log(err)
            enqueueSnackbar('Wystąpił błąd', {variant: 'error'});
        }
    }

    const handleClickOpenDeleteCreationModal = (creationId: number) => {
        setSelectedCreationToRemove(creationId);
    };

    const handleCloseDeleteCreationModal = (remove: boolean) => {
        if (remove && selectedCreationToRemove) {
            deleteCreationMutation(selectedCreationToRemove);
        }
        setSelectedCreationToRemove(null);
    };

    const {isLoading, isError, isSuccess, data} =
        useGetCreationsQuery(params.join('&').toString(), {skip: skipFetchingCreations});

    const {
        isLoading: loadingFiltersMeta,
        isError: isFiltersMetaError,
        isSuccess: isSuccessFiltersMeta,
        data: filtersMetaData
    } = useGetCreationsFiltersMetaQuery()

    const dispatch = useDispatch();
    const {t, i18n} = useTranslation(); // not passing any namespace will use the defaultNS (by default set to 'translation')

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const creations = data?.data
    const theme = useTheme();


    useDebounce(() => {
            dispatch(setCreationsFilters({
                ...currentFiltersState,
                nameCont: searchByName,
                page: 1
            }))
        }, [searchByName], 800
    );

    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setRowLength(parseInt(event.target.value, 10))
    }

    function removeFilter(filterElement: FilterItemChip) {
        if (filterElement.name === 'nameCont') {
            setSearchByName('');
        }
        dispatch(setCreationsFilters({
            ...currentFiltersState,
            page: 1,
            [filterElement.name]: ''
        }))
    }


    return (
        <Box className="CreationsList">
            <Box className={'page-header'}>
                <h1>Lista kreacji</h1>
            </Box>

            {(isLoading || loadingFiltersMeta) && (
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px'}}>
                    <CircularProgress size={100}/>
                </Box>)}

            {/*{isError && (*/}
            {/*    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px'}}>*/}
            {/*        <span>Error occurred...</span>*/}
            {/*    </Box>)}*/}

            {(creations && filtersMetaData) && (<>
                <Grid container spacing={2}>
                    <Grid item xs={4} alignItems="baseline">
                        <TextField
                            size="small"
                            value={searchByName}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setSearchByName(event.target.value);
                            }}
                            InputProps={{
                                endAdornment: <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => {
                                        setSearchByName('')
                                        dispatch(setCreationsFilters({
                                            ...currentFiltersState,
                                            nameCont: searchByName
                                        }))
                                    }
                                    }
                                    edge="end"
                                >
                                    {<Clear/>}
                                </IconButton>,
                            }}
                            margin="normal"
                            variant={"outlined"}
                            required
                            fullWidth
                            id="creationsName"
                            label="Nazwa kreacji"
                            name="creationsName"
                            autoFocus></TextField>
                    </Grid>
                </Grid>
                <Grid container spacing={2} pb={2}>
                    {filterItemChips.map((filter) => {
                        return (
                            <Grid item key={filter.value}>
                                <Chip
                                    color="info"
                                    label={filter.name + ': ' + filter.value}
                                    onClick={() => {
                                    }}
                                    onDelete={() => removeFilter(filter)}
                                />
                            </Grid>)
                    })}
                </Grid>
                <Backdrop
                    sx={(theme) => ({color: '#fff', zIndex: theme.zIndex.drawer + 1})}
                    open={deletingCreation || isLoading}
                >
                    <CircularProgress color="inherit"/>
                </Backdrop>
                <TableContainer component={Paper}>
                    <Table sx={{minWidth: 650}} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Nazwa kreacji</TableCell>
                                <TableCell align="right">Status</TableCell>
                                <TableCell align="right"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {creations.map((row) => (
                                <TableRow
                                    key={row.id}
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.name}
                                    </TableCell>
                                    <TableCell align="right">
                                        <Box sx={{display: 'flex', justifyContent: 'end', alignItems: 'center'}}>
                                           <CmStatusIconBox state={row.state}></CmStatusIconBox>
                                        </Box>
                                    </TableCell>
                                    <TableCell align="right">
                                    <ButtonGroup variant="text" aria-label="Basic button group">
                                            <Link to={`/creations/${row.id}`} className="link"><Button>Edycja
                                                kreacji</Button></Link>
                                            <Button color="error" onClick={() => {
                                                row.id && handleClickOpenDeleteCreationModal(row.id)
                                            }}>Usun kreacje</Button>
                                        </ButtonGroup>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={TABLE_ROW_LENGTH_OPTIONS}
                    component="div"
                    count={data.meta.count}
                    rowsPerPage={rowLength}
                    page={data.meta.page - 1}
                    onPageChange={(e, page) => dispatch(setCreationsFilters({
                        ...currentFiltersState,
                        page: page + 1
                    }))}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <Dialog
                    open={!!selectedCreationToRemove}
                    onClose={handleCloseDeleteCreationModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Usuwanie kreacji
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {'Usunąć kreacje ' + creations.filter(creation => creation.id === selectedCreationToRemove)[0]?.name + '?'}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant={'contained'} onClick={() => {
                            handleCloseDeleteCreationModal(true)
                        }}>{t('common.remove')}</Button>
                        <Button onClick={() => {
                            handleCloseDeleteCreationModal(false)
                        }} autoFocus>
                            {t('common.cancel')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </>)}
        </Box>

    )
}

export default CreationsList;