import {LockOutlined} from "@mui/icons-material";
import {Avatar, Box, Button, Container, CssBaseline, TextField, Typography,} from "@mui/material";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useLoginMutation} from "./authApiSlice";
import {setCredentials} from "./authSlice";
import {useDispatch} from "react-redux";
import {useSnackbar} from "notistack";

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errMsg, setErrMsg] = useState("");
    const navigate = useNavigate();
    const [login, {isLoading}] = useLoginMutation()
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        setErrMsg('');
    }, [email, password]);


    const handleLogin = async (event: any) => {
        event.preventDefault();

        try {
            const {accessToken, refreshToken, profile} = await login({email, password}).unwrap()
            dispatch(setCredentials({accessToken, refreshToken, profile}))
            setEmail('');
            setPassword('');
            navigate('/')
            enqueueSnackbar(`Pomyślnie zalogowano użytkownika ${email}`,{variant: 'success'});
        } catch (err: any) {
            if (!err?.response) {
                setErrMsg('No server response');
            } else if (err?.response?.status === 400) {
                setErrMsg('Missing username or password');
            } else if (err?.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login failed');
            }

        }
    }


    return (
        <>
            <Container maxWidth="xs">
                <CssBaseline/>
                <Box
                    sx={{
                        mt: 20,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Avatar sx={{m: 1, bgcolor: "primary.light"}}>
                        <LockOutlined/>
                    </Avatar>
                    <Typography variant="h5">Login</Typography>
                    <Box sx={{mt: 1}}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoFocus
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />

                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="password"
                            name="password"
                            label="Password"
                            type="password"
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }}
                        />

                        <Button
                            fullWidth
                            variant="contained"
                            sx={{mt: 3, mb: 2}}
                            onClick={handleLogin}
                        >
                            Login
                        </Button>
                        {/*<Grid container justifyContent={"flex-end"}>*/}
                        {/*    <Grid item>*/}
                        {/*        <Link to="/register">Don't have an account? Register</Link>*/}
                        {/*    </Grid>*/}
                        {/*</Grid>*/}
                    </Box>
                </Box>
            </Container>
        </>
    );
};

export default Login;