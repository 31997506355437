import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField
} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {initialDraftFormState, selectDraftForm, setDraftForm, setFilters} from "../store/draftsSlice";
import {CustomProp, Media, MediaNode, PropertyAttribute, PropType} from "../types";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {useTranslation} from "react-i18next";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";
import {styled, useTheme} from "@mui/material/styles";
import {
    useCreateDraftMutation, useDeleteDraftMutation,
    useGetDraftQuery,
    useGetMediaTreeQuery,
    useGetTagsQuery,
    useUpdateDraftMutation
} from "../api/draftsApiSlice";
import {useNavigate, useParams} from "react-router-dom";
import {enqueueSnackbar, useSnackbar} from "notistack";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {useGetCmEnumsQuery} from "../../../app/appApiSlice";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import CheckboxTree from "react-checkbox-tree";
import useDebounce from '../../../hooks/useDebounce';
import Link from '@mui/material/Link';
import DialogContentText from '@mui/material/DialogContentText';
import {VisuallyHiddenInput} from "../../../config/visuallyHiddenInput";

const DraftForm = () => {


    const [createDraft, {isLoading}] = useCreateDraftMutation()
    const [updateDraft, {isLoading: updatingDraft}] = useUpdateDraftMutation()
    const {isLoading: loadingCmEnums, data: cmEnums} =
        useGetCmEnumsQuery();
    const {isLoading: loadingTags, data: tagsData} =
        useGetTagsQuery();
    const [openMediaTreeModal, setOpenMediaTreeModal] = useState(false);
    let fetchMediaTree = false
    const draftForm = useSelector(selectDraftForm);
    const {isLoading: loadingMediaTree, data: mediaTree} =
        useGetMediaTreeQuery(draftForm.mediaFormat || '', {skip: !draftForm.mediaFormat});
    const {id: urlId} = useParams()
    const {isLoading: loadingDraftDetails, data: draftDetails} =
        useGetDraftQuery(urlId || '', {skip: !urlId});
    const [expanded, setExpanded] = useState([]);
    const [checkedNodes, setCheckedNodes] = useState([]);
    const [treeState, setTreeState] = useState({key: Math.random()});
    const [nodes, setNodes] = useState([]);

    const [filteredNodes, setFilteredNodes] = useState([]);
    const [nameError, setNameError] = useState(false)
    const [filterNodesText, setFilterNodesText] = useState('')
    const {t, i18n} = useTranslation(); // not passing any namespace will use the defaultNS (by default set to 'translation')
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const [activeStep, setActiveStep] = useState(0);
    const [customProp, setCustomProp] = useState<CustomProp>({name: '', propType: PropType.String});
    const [skipped, setSkipped] = useState(new Set<number>());
    const steps = [t("draftForm.steps.schemaParameters.title"), t(urlId ? "draftForm.steps.createSchema.updateTitle" : "draftForm.steps.createSchema.createTitle")];
    const [file, setFile] = useState<File | null>(null);
    const [mediaLength, setMediaLength] = useState<number | null>(null);
    const [draftFileUrl, setDraftFileUrl] = useState<string | null>(null);
    const [openRemovalModal, setOpenRemovalModal] = React.useState<boolean>(false);
    const [deleteDraft, {isLoading: deletingDraft}] = useDeleteDraftMutation()

    const checkboxTreeRef = useRef<any>()

    const theme = useTheme();
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const handleOpenNodesModal = () => {
        setOpenMediaTreeModal(true)
    };
    const handleCloseNodesModal = () => {
        setOpenMediaTreeModal(false)
    };

    const deleteDraftMutation = async (draftId: number) => {
        try {
            const result = await deleteDraft(draftId)
            if (!result?.error) {
                navigate('/')
                enqueueSnackbar('Pomyślnie usunięto schemat', {variant: 'success'});
            } else {
                enqueueSnackbar('Wystąpił błąd', {variant: 'error'});
            }
        } catch (err: any) {
            enqueueSnackbar('Wystąpił błąd', {variant: 'error'});
        }
    }

    const handleCloseDeleteDraftModal = (remove: boolean) => {
        if (remove && draftDetails && draftDetails.id) {
            deleteDraftMutation(draftDetails.id);
        }
        setOpenRemovalModal(false);
    };

    let mediaSize = 0

    const buildMediaTreeCheckboxNodes: any = (mediaTree: MediaNode[]) => {
        return mediaTree.map((element: MediaNode) => {
            if (element.children && element.children.length > 0) {
                return {
                    value: element.id + '_' + element.type,
                    label: element.name + ' ' + `${element.type === 'Media::Agglomeration' ? '(Aglomeracja)' : '(Miasto)'}`,
                    children: buildMediaTreeCheckboxNodes(element.children),
                }
            } else {
                mediaSize += 1;
                return {
                    value: element.id + '_' + element.type,
                    label: element.asId ? element.asId + ' - ' + element.name : element.name,
                }
            }
        })
    }

    useEffect(() => {
        if (mediaTree) {
            mediaSize = 0;
            const mediaTreeCheckboxNodes = buildMediaTreeCheckboxNodes(mediaTree)
            setTimeout(() => {
                setNodes(mediaTreeCheckboxNodes)
                setFilteredNodes([{
                    label: 'Wszystkie nośniki',
                    value: 'all',
                    children: mediaTreeCheckboxNodes
                }] as any)
                setMediaLength(mediaSize)
            }, 200)
        }


    }, [mediaTree]);

    useEffect(() => {
        const checkedMedia = checkedNodes.filter(el => el !== 'all').map((node: string) => {
            return node.split('_')[0]
        })

        const mediaToAdd = checkedMedia.map((checkedMediaElement) => {
            return {localisableId: checkedMediaElement, localisableType: 'Medium'}
        })
        dispatch(setDraftForm({
            ...draftForm,
            localisablesMediatablesAttributes: [ ...mediaToAdd as []]
        }));
    }, [checkedNodes])


    useEffect(() => {
        if (draftDetails && urlId) {
            const {draftProperties, media, ...rest} = draftDetails;
            dispatch(setDraftForm({
                ...rest,
                draftPropertiesAttributes: draftProperties,
                localisablesMediatablesAttributes: media.map((mediaItem) => {
                    return {localisableId: mediaItem.id, localisableType: 'Medium'}
                })
            }));
            setCheckedNodes(media.map((mediaElement: Media) => {
                return mediaElement.id + '_Medium'
            }) as any)
            setDraftFileUrl(draftDetails.draftFileUrl)
        } else {
            dispatch(setDraftForm({
                ...initialDraftFormState.draftForm
            }));
            setCheckedNodes([])
        }
    }, [draftDetails, urlId])

    useDebounce(() => {
            // Reset nodes back to unfiltered state
            if (!filterNodesText) {
                setFilteredNodes([{
                    label: 'Wszystkie nośniki',
                    value: 'all',
                    children: nodes
                }] as any);
                return;
            }
            setFilteredNodes(
                [{
                    label: 'Wszystkie nośniki',
                    value: 'all',
                    children: nodes.reduce(filterNodes, [])
                }] as any)
            checkboxTreeRef.current.onExpandAll()
        }, [filterNodesText], 100
    );


    const isStepOptional = (step: number) => {
        return step === 1;
    };


    const isStepSkipped = (step: number) => {
        return skipped.has(step);
    };

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && draftForm.id) {
            setFile(e.target.files[0]);
            // await uploadDraftFile({draftId: draftForm.id, file: e.target.files[0]})

            const xhr = new XMLHttpRequest();
            xhr.onreadystatechange = function () {
                if (xhr.readyState === XMLHttpRequest.DONE) {
                    const responseData = JSON.parse(xhr.responseText)
                    setDraftFileUrl(responseData.data.draft_file_url);
                }
            }

            xhr.open('PATCH', `${process.env.REACT_APP_DSP_BACKEND_API}/api/v1/drafts/${draftForm.id}/upload`);
            // xhr.setRequestHeader("Content-Type","multipart/form-data");
            let token = ''
            if (localStorage.getItem("user")) {
                token = JSON.parse(localStorage.getItem("user") || '').accessToken;
            }
            if (token) {
                xhr.setRequestHeader("authorization", `Bearer ${token}`);
            }
            let bodyFormData = new FormData();
            bodyFormData.append('draft[draft_file]', e.target.files[0]);

            xhr.send(bodyFormData)
        }
    };

    const createBasicDraft = async (event: any) => {
        event.preventDefault()

        setNameError(false)

        if (draftForm.name == '') {
            setNameError(true)
        }

        if (draftForm.name) {
            try {
                const result = await createDraft(draftForm).unwrap()
                dispatch(setDraftForm({...draftForm, id: result.data.id}))
            } catch (err: any) {
                enqueueSnackbar('Wystąpił błąd', {variant: 'error'});
            }
        }
    }


    function nextStep() {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);

        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    }

    const handleNext = async (event: any) => {

        event.preventDefault()

        if (activeStep === steps.length - 1) {
            try {
                await updateDraft(draftForm).unwrap()
                dispatch(setDraftForm({...initialDraftFormState.draftForm}))
                setCheckedNodes([])
                navigate('/')
                enqueueSnackbar('Pomyślnie utworzono schemat', {variant: 'success'});
            } catch (err: any) {
                enqueueSnackbar('Wystąpił błąd', {variant: 'error'});
            }
        }


        setNameError(false)

        if (draftForm.name === '') {
            setNameError(true)
        }

        if (draftForm.name && !draftForm.id) {
            try {
                const result = await createDraft(draftForm).unwrap()
                dispatch(setDraftForm({...draftForm, id: result.data.id}))
                nextStep()
            } catch (err: any) {
                enqueueSnackbar('Wystąpił błąd', {variant: 'error'});
            }
        }


        if (draftForm.id) {
            nextStep();
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const onFilterNodeChange = (event: any) => {
        setFilterNodesText(event.target.value)
    }

    const filterNodes = (filtered: any, node: any) => {
        if (
            // Node's label matches the search string
            node.label.toLocaleLowerCase().match(filterNodesText.toString().toLocaleLowerCase())
        ) {
            filtered.push(node)
            return filtered;
        }

        if (node.children && node.children.length > 0) {
            const children = (node.children || []).reduce(filterNodes, []);
            if (children.length > 0) {
                filtered.push({...node, children});
            }
        }
        return filtered;
    }

    const handleReset = () => {
        setActiveStep(0);
    };

    const addCustomProp = () => {

    }


    function getStyles() {
        return {
            fontWeight: theme.typography.fontWeightMedium
        };
    }

    const schemaParametersStep = <Box sx={{paddingTop: '40px'}}>
        <form autoComplete="off" onSubmit={createBasicDraft}>
            <TextField
                value={draftForm.name}
                label="Nazwa schematu"
                onChange={(event) => {
                    dispatch(setDraftForm({
                        ...draftForm,
                        name: event.target.value
                    }));
                }}
                required
                variant="outlined"
                sx={{mb: 3}}
                fullWidth
                error={nameError}/>
            <FormControl fullWidth sx={{marginTop: '15px'}}>
                <InputLabel shrink id="demo-simple-select-label">Format nośnika</InputLabel>
                <Select
                    variant={"outlined"}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Format nośnika"
                    value={draftForm.mediaFormat || ''}
                    notched
                    onChange={(event) => {
                        setCheckedNodes([])
                        dispatch(setDraftForm({
                            ...draftForm,
                            mediaFormat: event.target.value
                        }));
                    }}
                >
                    <MenuItem value="">
                        <em>Brak</em>
                    </MenuItem>
                    {cmEnums && cmEnums.find(cmEnum => cmEnum.name === 'mediaFormats')?.values.map((mediaFormat) => (
                        <MenuItem
                            key={mediaFormat}
                            value={mediaFormat}>{mediaFormat}</MenuItem>
                    ))}
                </Select>
            </FormControl>


            <FormControl fullWidth sx={{marginTop: '45px'}}>
                <InputLabel id="demo-multiple-chip-label">Tagi</InputLabel>
                <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    value={draftForm.tagList}
                    onChange={(event) => {
                        dispatch(setDraftForm({
                            ...draftForm,
                            tagList: typeof event.target.value === "string" ? [event.target.value] : event.target.value
                        }));
                    }}
                    input={<OutlinedInput id="select-multiple-chip" label="Chip"/>}
                    renderValue={(selected) => (
                        <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                            {selected.map((value) => (
                                <Chip key={value} label={value}/>
                            ))}
                        </Box>
                    )}
                    MenuProps={MenuProps}
                >
                    {tagsData && (tagsData.map((tag) => (
                        <MenuItem
                            key={tag.name}
                            value={tag.name}
                            style={getStyles()}
                        >
                            {tag.name}
                        </MenuItem>
                    )))}
                </Select>
            </FormControl>
            <Button disabled={loadingMediaTree || !draftForm.mediaFormat} variant="contained"
                    onClick={handleOpenNodesModal} sx={{marginTop: '20px'}}>{t('draftForm.mediaPicker')}&nbsp;
                {(loadingMediaTree) && draftForm.mediaFormat ? <CircularProgress sx={{marginLeft: '10px'}} size={20}/> :`${checkedNodes.length}/${mediaLength || 0}`}</Button>
            <Dialog
                open={openMediaTreeModal}
                onClose={handleCloseNodesModal}
                fullWidth={true}
                maxWidth="md"
            >

                <DialogTitle id="alert-dialog-title">
                    {t("draftForm.chooseMedia")}
                </DialogTitle>
                <DialogContent>

                    {(loadingMediaTree) && (
                        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px'}}>
                            <CircularProgress size={100}/>
                        </Box>)}

                    <Box sx={{minHeight: '300px'}}>
                        {mediaTree && (
                            <><TextField
                                value={filterNodesText}
                                label="Wyszukaj..."
                                onInput={onFilterNodeChange}
                                variant="outlined"
                                sx={{mt: 1, mb: 3}}
                                fullWidth
                            />
                                <CheckboxTree
                                    ref={checkboxTreeRef}
                                    key={treeState.key}
                                    showNodeTitles={true}
                                    nodes={filteredNodes}
                                    checked={checkedNodes}
                                    expanded={expanded}
                                    showExpandAll={true}
                                    onCheck={(checked: any) => {
                                        setCheckedNodes(checked)
                                        setTreeState({key: Math.random()})
                                    }}
                                    onExpand={(expanded: any) => {
                                        setExpanded(expanded)
                                    }}
                                /></>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseNodesModal}>{t('common.closeWindow')}</Button>
                </DialogActions>


            </Dialog>
        </form>
    </Box>


    const createSchemaStep =
        <Box sx={{paddingTop: '40px'}}>
            <form autoComplete="off" onSubmit={createBasicDraft}>
                <h4>{t("draftForm.customProperty.title")}</h4>
                <TableContainer sx={{width: 650, marginBottom: '30px'}} component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('draftForm.customProperty.name')}</TableCell>
                                <TableCell>{t('draftForm.customProperty.propertyType')}</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {draftForm.draftPropertiesAttributes.filter(el => !el._destroy).map((row: PropertyAttribute, index) => (
                                <TableRow
                                    key={index}
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.name}
                                    </TableCell>
                                    <TableCell>{t(`drafts.propType.${row.propType}`)}</TableCell>
                                    <TableCell><Button color="error" variant="text" onClick={() => {
                                        dispatch(setDraftForm({
                                            ...draftForm,
                                            draftPropertiesAttributes:
                                                row.id ?
                                                [...draftForm.draftPropertiesAttributes.map((prop) => {
                                                if (prop.id === row.id) {
                                                    return {...prop, _destroy: true}
                                                }
                                                return prop
                                            })] : [...draftForm.draftPropertiesAttributes.filter(prop => prop !== row)],
                                        }));
                                    }}>{t('common.remove')}</Button></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'baseline'}}><TextField
                    value={customProp.name}
                    label={t("draftForm.customProperty.name")}
                    onChange={(event) => {
                        setCustomProp({name: event.target.value, propType: customProp.propType});
                    }}
                    required
                    variant="outlined"
                    sx={{mb: 3, mr: 3}}
                    error={nameError}/>
                    <FormControl>
                        <InputLabel
                            id="demo-simple-select-label">{t("draftForm.customProperty.propertyType")}</InputLabel>
                        <Select
                            sx={{minWidth: 200}}
                            variant={"outlined"}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label={t("draftForm.customProperty.propertyType")}
                            value={customProp.propType}
                            notched
                            onChange={(event) => {
                                setCustomProp({name: customProp.name, propType: event.target.value});
                            }}
                        >
                            {cmEnums && cmEnums.find(cmEnum => cmEnum.name === 'propTypes')?.values.map((propType) => (
                                <MenuItem
                                    key={propType}
                                    value={propType}>
                                    {t(`drafts.propType.${propType}`)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Button disabled={!customProp.name} variant="text"
                            sx={{marginLeft: '20px', height: '55px', width: '120px'}} onClick={() => {
                        dispatch(setDraftForm({
                            ...draftForm,
                            draftPropertiesAttributes: [...draftForm.draftPropertiesAttributes, {
                                name: customProp.name,
                                propType: customProp.propType
                            }],
                        }));
                        setCustomProp({name: '', propType: customProp.propType});

                    }}>{t("common.add")}</Button>
                </Box>
                <h4>{t("common.uploadFile")}</h4>
                <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '20px'}}
                ><Button
                    component="label"
                    role={undefined}
                    variant="outlined"
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon/>}
                >
                    {t("draftForm.draftFile")}
                    <VisuallyHiddenInput type="file" accept=".zip,.rar,.7zip" onChange={handleFileChange}/>
                </Button>
                    {(draftFileUrl) && (<Link href={draftFileUrl}>{t("draftForm.downloadDraftFile")}</Link>)}
                </Box>
            </form>
        </Box>

    return (
        <>
            <Box className={'page-header'}>
                <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'baseline'}}>
                    <h1>{urlId ? t("draftForm.editSchema") + " " + draftForm.name : t("draftForm.newSchemaCreator")}</h1>
                    {urlId && (<Button onClick={() => {setOpenRemovalModal(true)}} variant={'contained'} color={'error'}>{t('drafts.removeDraft')}</Button>)}
                </Box>
                <p>{t("draftForm.steps.schemaParameters.title")}</p>
            </Box>
            <Box sx={{width: '100%', paddingTop: '20px'}}>
                <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => {
                        const stepProps: { completed?: boolean } = {};
                        const labelProps: {
                            optional?: React.ReactNode;
                        } = {};
                        if (isStepSkipped(index)) {
                            stepProps.completed = false;
                        }
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                {activeStep === steps.length ? (
                    <React.Fragment>
                        <Typography sx={{mt: 2, mb: 1}}>
                            All steps completed - you&apos;re finished
                        </Typography>
                        <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                            <Box sx={{flex: '1 1 auto'}}/>
                            <Button onClick={handleReset}>Reset</Button>
                        </Box>
                    </React.Fragment>
                ) : (
                    <>
                        {activeStep === 0 && (schemaParametersStep)}

                        {activeStep === 1 && (createSchemaStep)}

                        <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                            {activeStep !== 0 && (<Button
                                color="inherit"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{mr: 1}}
                            >
                                {t("common.back")}
                            </Button>)
                            }
                            <Box sx={{flex: '1 1 auto'}}/>
                            <Button onClick={handleNext} disabled={!draftForm.name}>
                                {activeStep === steps.length - 1 ? (urlId ? t("draftForm.steps.createSchema.updateTitle") : t("draftForm.steps.createSchema.createTitle")) : t("common.next")}
                            </Button>
                        </Box>
                    </>
                )}
            </Box>
            <Dialog
                open={openRemovalModal}
                onClose={handleCloseDeleteDraftModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t('drafts.removingDraft')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t('drafts.removalConfirmation', {draftName: draftDetails?.name })}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant={'contained'} onClick={() => {
                        handleCloseDeleteDraftModal(true)
                    }}>{t('common.remove')}</Button>
                    <Button onClick={() => {
                        handleCloseDeleteDraftModal(false)
                    }} autoFocus>
                        {t('common.cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DraftForm;
