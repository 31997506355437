import {baseApi} from "../../app/api/baseApi";
import {AuthResponse, LoginFetchArgs, LoginPayload} from "./types";


export const authApiSlice = baseApi.injectEndpoints({
    endpoints: builder => ({
        login: builder.mutation<AuthResponse, LoginPayload>({
            query: (authPayload: LoginPayload): LoginFetchArgs => {
                return {
                    url: '/oauth/token',
                    method: 'POST',
                    body: {...authPayload, grantType: 'password'}
                }
            },
            transformResponse: (loginResponse: AuthResponse) => {
                return loginResponse
            }
        }),
    })
})

export const {useLoginMutation} = authApiSlice