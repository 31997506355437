import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {ProSidebarProvider} from 'react-pro-sidebar';
import {SnackbarProvider} from "notistack";
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import en from "../src/translation/en/en.json";
import pl from "../src/translation/pl/pl.json";
import 'react-checkbox-tree/lib/react-checkbox-tree.css';


i18next.init({
    interpolation: {escapeValue: false},  // React already does escaping
    lng: 'pl',                              // language to use
    resources: {
        en: {
            translation: en               // 'common' is our custom namespace
        },
        pl: {
            translation: pl               // 'common' is our custom namespace
        },
    },
});
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <ProSidebarProvider>
                <SnackbarProvider maxSnack={3}>
                    <I18nextProvider i18n={i18next}>
                        <App/>
                    </I18nextProvider>
                </SnackbarProvider>
            </ProSidebarProvider>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
