import {ApiTags, baseApi} from "../../../app/api/baseApi";
import {CmQueryResult} from "../../../app/types";
import {
    ChangeMediabankResourceNamePayload,
    ChangeResourceLocationPayload,
    MediabankResource,
    MediabankResourceWithPropID,
    ResourcePayload
} from "../types";
import UseApiV1 from "../../../hooks/useApiV1";

export const mediabankResourcesApiSlice = baseApi.injectEndpoints({
    endpoints: builder => ({
        getMediabankResources: builder.query<CmQueryResult<MediabankResource[]>, string>({
            query: (query) => {
                return {
                    reducerPath: 'mediabankResources',
                    url: UseApiV1(`mediabank_resources?${query}`),
                    method: 'GET',
                }
            },
            keepUnusedDataFor: 120,
            providesTags: (_) => [ApiTags.MediabankResourcesList],
        }),
        getMediabankResourcesForSelect: builder.query<CmQueryResult<MediabankResource[]>, string | null>({
            query: (parentId: string | null) => {
                return {
                    reducerPath: 'mediabankResources',
                    url: UseApiV1(parentId? `mediabank_resources?q[parent_id_eq]=${parentId}` : 'mediabank_resources'),
                    method: 'GET',
                }
            },
            keepUnusedDataFor: 120,
            providesTags: (_) => [ApiTags.MediabankResourcesList],
        }),
        createResource: builder.mutation<any, ResourcePayload>({
            query: (mediabankResourcePayload: ResourcePayload): any => {
                return {
                    url: UseApiV1('mediabank_resources'),
                    method: 'POST',
                    body: {...mediabankResourcePayload}
                }
            },
            invalidatesTags: (_) => [ApiTags.MediabankResourcesList],
        }),
        changeResourceName: builder.mutation<any, ChangeMediabankResourceNamePayload>({
            query: (changeMediabankResourceNamePayload:ChangeMediabankResourceNamePayload): any => {
                return {
                    url: UseApiV1(`mediabank_resources/${changeMediabankResourceNamePayload.resourceId}`),
                    method: 'PATCH',
                    body: {name: changeMediabankResourceNamePayload.name}
                }
            },
            invalidatesTags: (_) => [ApiTags.MediabankResourcesList],
        }),
        getMediabankResource: builder.query<CmQueryResult<MediabankResource>, number>({
            query: (id) => {
                return {
                    reducerPath: 'mediabankResources',
                    url: UseApiV1(`mediabank_resources/${id}`),
                    method: 'GET',
                }
            },
            keepUnusedDataFor: 120,
        }),
        getMediabankResourcesBulk: builder.query<MediabankResourceWithPropID[], {resourceId: number, propId: number}[]>({
            queryFn: (resourcesIds: {resourceId: number, propId: number}[], queryApi) => {
                const promises: Promise<any>[] = resourcesIds.map((resourcesIds) => {
                    return queryApi.dispatch(mediabankResourcesApiSlice.endpoints.getMediabankResource.initiate(resourcesIds.resourceId)).then((data) => {
                        return {...data, propId: resourcesIds.propId}
                    });
                });
                return Promise.all(promises).then((results) => {
                    return { data: results.map((el) => {return {...el.data.data, propId: el.propId}}) };
                });
            },
        }),
        changeResourceLocation: builder.mutation<any, ChangeResourceLocationPayload[]>({
            query: (changeResourceLocationPayload: ChangeResourceLocationPayload[]): any => {
                return {
                    url: UseApiV1('mediabank_resources_bulk'),
                    method: 'PATCH',
                    body: changeResourceLocationPayload
                }
            },
            invalidatesTags: (_) => [ApiTags.MediabankResourcesList],
        }),
    })
})

export const {
    useGetMediabankResourcesQuery,
    useGetMediabankResourcesForSelectQuery,
    useCreateResourceMutation,
    useChangeResourceNameMutation,
    useLazyGetMediabankResourceQuery,
    useLazyGetMediabankResourcesBulkQuery,
    useChangeResourceLocationMutation
} = mediabankResourcesApiSlice