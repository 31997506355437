import {baseApi} from "./api/baseApi";
import {CmEnum, CmEnumsResponse, CmQueryResult, Tag} from "./types";
import {camelize} from "humps";


export const appApiSlice = baseApi.injectEndpoints({
    endpoints: builder => ({
        getCmEnums: builder.query<CmEnum[], void>({
            query: () => {
                return {
                    reducerPath: 'cmEnums',
                    url: `/api/v1/cm_enums`,
                    method: 'GET',
                }
            },
            keepUnusedDataFor: 120,
            transformResponse: (response: CmEnumsResponse) => {
                return response.data.map(cmEnum => {
                    return {
                        name: camelize(cmEnum.name),
                        values: cmEnum.values,
                    }
                });
            },
        }),
        getCmTags: builder.query<Tag[], void>({
            query: () => {
                return {
                    reducerPath: 'cmTags',
                    url: `/api/v1/tags`,
                    method: 'GET',
                }
            },
            keepUnusedDataFor: 120,
            transformResponse: (response: CmQueryResult<Tag[]>) => {
               return response.data
            },
        }),
    })
})

export const {useGetCmEnumsQuery, useGetCmTagsQuery} = appApiSlice