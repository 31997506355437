import {
    Autocomplete, Backdrop,
    Box,
    Breadcrumbs,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Popper,
    TextField,
    Typography
} from '@mui/material';
import React, {HTMLInputTypeAttribute, useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import {useGetDraftQuery, useGetMediaTreeQuery} from '../../drafts/api/draftsApiSlice';
import {useDispatch, useSelector} from 'react-redux';
import {initialCreationFormState, selectCreationForm, setCreationForm} from '../store/creationsSlice';
import {ClearIcon, DateTimePicker} from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {useGetCmEnumsQuery} from '../../../app/appApiSlice';
import {Draft, MediaNode, PropertyAttribute} from '../../drafts/types';
import CheckboxTree from 'react-checkbox-tree';
import useDebounce from '../../../hooks/useDebounce';
import Chip from '@mui/material/Chip';
import './CreationForm.scss'
import {CreationPayload, CreationProperty, PossibleEvent} from "../types";
import DraftsList from "../../drafts/list/DraftsList";
import {
    useCreateCreationMutation,
    useDeleteCreationMutation,
    useGetCreationDetailsQuery,
    useGetResponsiblePersonsQuery, useManageStateMutation,
    useUpdateCreationFillInParametersStepMutation,
    useUpdateCreationMutation
} from "../api/creationsApiSlice";
import {useSnackbar} from "notistack";
import {BreadCrumb} from "../../mediabank/store/mediabankResourcesSlice";
import {
    useGetMediabankResourcesForSelectQuery,
    useLazyGetMediabankResourceQuery,
    useLazyGetMediabankResourcesBulkQuery
} from "../../mediabank/api/mediabankResourcesApiSlice";
import Link from "@mui/material/Link";
import {MediabankResourceWithPropID, ResourceType} from "../../mediabank/types";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import {Thumbnail} from "../../../config/images";
import DialogContentText from "@mui/material/DialogContentText";
import CheckIcon from "@mui/icons-material/Check";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import ModeIcon from "@mui/icons-material/Mode";
import CmStatusIconBox from "../../../components/CmStatusIconBox/CmStatusIconBox";

interface SelectedMediabankResource {
    propId: number;
    resourceId: number;
    name: string;
    thumbnailUrl: string | null;
    resourceUrl: string | null;
}

const CreationForm = () => {

    const [formErrors, setFormErrors] = useState({
        name: false,
        sideDescription: false,
        startAt: false,
        endAt: false
    })
    const {creationId, draftId} = useParams()
    const mediabankBreadcrumbsInitialState = [{
        dirName: 'Katalog główny',
        resourceId: null
    }]

    const {t} = useTranslation();
    const [activeStep, setActiveStep] = useState(0);
    const [steps, setSteps] = useState<string[]>([]);
    const [openMediabankDialog, setOpenMediabankDialog] = React.useState<number | null | undefined>(null);
    const [selectedMediabankResources, setSelectedMediabankResources] = React.useState<SelectedMediabankResource[]>([]);
    const [breadcrumbsForMediabankDialog, setBreadcrumbsForMediabankDialog] = React.useState<BreadCrumb[]>(mediabankBreadcrumbsInitialState);
    const [parentIdForForMediabankDialog, setParentIdForMediabankDialog] = React.useState<string | null>(null);
    const [openRemoveCreationDialog, setOpenRemoveCreationDialog] = React.useState<boolean>(false);

    const [skipped, setSkipped] = useState(new Set<number>());
    const dispatch = useDispatch();
    const {isLoading: loadingCreationDetails, data: creationDetails} =
        useGetCreationDetailsQuery(creationId || '', {skip: !creationId});

    const creationForm = useSelector(selectCreationForm);
    const {isLoading: loadingDraftDetails, data: draftDetails} =
        useGetDraftQuery(draftId || '', {skip: !draftId});

    const {isLoading: loadingResponsiblePersons, data: responsiblePersons} =
        useGetResponsiblePersonsQuery();

    const {isLoading: loadingCmEnums, data: cmEnums} =
        useGetCmEnumsQuery();

    const {isLoading: loadingMediaTree, data: mediaTree} =
        useGetMediaTreeQuery();

    const {isLoading: loadingResourcesForMediabankDialog, data: resourcesDataForMediabankDialog} =
        useGetMediabankResourcesForSelectQuery(parentIdForForMediabankDialog, {skip: !openMediabankDialog});

    const [getFile, {data: FileData}] = useLazyGetMediabankResourceQuery()

    const [getResources, {data: resourcesBulkData}] = useLazyGetMediabankResourcesBulkQuery()

    const [createCreation, {isLoading}] = useCreateCreationMutation();

    const [updateCreationFillIinParametersStep, {isLoading: updatingFillIinParametersStep}] = useUpdateCreationFillInParametersStepMutation();

    const [updateCreation, {isLoading: updatingCreation}] = useUpdateCreationMutation();

    const [deleteCreation, {isLoading: deletingCreation}] = useDeleteCreationMutation()

    const [manageState, {isLoading: loadingManageStateUpdate}] = useManageStateMutation()


    const isStepSkipped = (step: number) => {
        return skipped.has(step);
    };
    const {enqueueSnackbar} = useSnackbar();


    const [nodes, setNodes] = useState([]);
    const [filteredNodes, setFilteredNodes] = useState([]);

    const [filterNodesText, setFilterNodesText] = useState('')

    const [mediaLength, setMediaLength] = useState<number | null>(null);
    const [checkedNodes, setCheckedNodes] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const [openMediaTreeModal, setOpenMediaTreeModal] = useState(false);
    const [authorizedPerson, setAuthorizedPerson] = useState<number | null>(null);
    const [authorizedPersonRights, setAuthorizedPersonRights] = useState('owner')
    const [openAddAuthorizedPersonModal, setOpenAddAuthorizedPersonModal] = useState(false);
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const checkboxTreeRef = useRef<any>()
    const [treeState, setTreeState] = useState({key: Math.random()});
    const navigate = useNavigate();

    const disableNextButton = (activeStep === 0 && !draftId) || (activeStep === 1 && !creationForm.name) || (activeStep === 2 && !creationForm.id);

    const filterNodes = (filtered: any, node: any) => {
        if (
            // Node's label matches the search string
            node.label.toLocaleLowerCase().match(filterNodesText.toString().toLocaleLowerCase())
        ) {
            filtered.push(node)
            return filtered;
        }

        if (node.children && node.children.length > 0) {
            const children = (node.children || []).reduce(filterNodes, []);
            if (children.length > 0) {
                filtered.push({...node, children});
            }
        }
        return filtered;
    }

    const handleNext = async () => {
        if (activeStep == 1) {
            if ((!creationForm.name || !draftId) && !creationDetails) {
                setFormErrors({...formErrors, name: !creationForm.name});
                return;
            }
            if (!creationForm.id && draftId) {
                try {
                    const creationPayload: CreationPayload = {
                        name: creationForm.name,
                        draftId: parseInt(draftId),
                        draftPropertiesAttributes: creationForm.creationPropertiesAttributes
                    }

                    const result = await createCreation(creationPayload).unwrap()
                    dispatch(setCreationForm({...creationForm, id: result.data.id}))
                } catch (err: any) {
                    enqueueSnackbar('Wystąpił błąd', {variant: 'error'});
                }
            }
        }

        if (activeStep === 2) {
            if (!creationForm.startAt) {
                setFormErrors({...formErrors, startAt: !creationForm.startAt});
                return;
            }
            if (creationForm.id) {
                try {
                    await creationId ? updateCreation(creationForm) : updateCreationFillIinParametersStep(creationForm).unwrap()
                    dispatch(setCreationForm({...initialCreationFormState.creationForm}))
                    setCheckedNodes([])
                    navigate('/creations')
                    enqueueSnackbar(`Pomyślnie ${creationId ? 'zaktualizowano' : 'utworzono'} kreacje`, {variant: 'success'});
                } catch (err: any) {
                    dispatch(setCreationForm({...initialCreationFormState.creationForm}))
                    setCheckedNodes([])
                    enqueueSnackbar('Wystąpił błąd', {variant: 'error'});
                    navigate('/creations')
                }
            }
        }

        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);

        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    let mediaSize = 0

    let currentElement: MediaNode | null = null;

    function buildMediaTreeCheckboxNodes(mediaTreeElements: MediaNode[]): any {
        return mediaTreeElements
            .map((element: MediaNode, index, array) => {
                if (element.children && element.children.length > 0) {
                    return {
                        value: element.id + '_' + element.type,
                        label: element.name + ' ' + `${element.type === 'Media::Agglomeration' ? '(Aglomeracja)' : '(Miasto)'}`,
                        children: buildMediaTreeCheckboxNodes(element.children),
                    }
                } else {
                    const media = draftDetails ? draftDetails?.media : creationDetails!.draftMedia
                    const shouldShowMedia = media.some(media => media.id === element.id)
                    if (shouldShowMedia) {
                        mediaSize += 1;
                    }
                    return {
                        showOnTree: shouldShowMedia,
                        value: element.id + '_' + element.type,
                        label: element.asId ? element.asId + ' - ' + element.name : element.name,
                    }
                }
            })
    }


    const findMatchingMediaNode: any = (mediaTreeElements: any) => {
        if (mediaTreeElements.children) {
            findMatchingMediaNode(mediaTreeElements.children);
        } else {
            return mediaTreeElements.showOnTree
        }
    }
    const handleCloseNodesModal = () => {
        setOpenMediaTreeModal(false)
    };

    const onFilterNodeChange = (event: any) => {
        setFilterNodesText(event.target.value)
    }

    const onSelectDraft = (draft: Draft) => {
        navigate(`/drafts/${draft.id}/new-creation`)
    }

    useEffect(() => {
        const checkedMedia = checkedNodes.filter(el => el !== 'all').map((node: string) => {
            return node.split('_')[0]
        })

        const mediaToAdd = checkedMedia.map((checkedMediaElement) => {
            return {localisableId: checkedMediaElement, localisableType: 'Medium'}
        })
        dispatch(setCreationForm({
            ...creationForm,
            localisablesMediatablesAttributes: [...mediaToAdd as []]
        }));
    }, [checkedNodes])

    useEffect(() => {
        if (!draftId && !creationId) {
            dispatch(setCreationForm(initialCreationFormState.creationForm))
            setSteps([`${t("creations.steps.step")} 1 - ${t("creations.steps.selectDraft")}`, `${t("creations.steps.step")} 2 - ${t("creations.steps.adjustCreationViewStep.stepTitle")}`, `${t("creations.steps.step")} 3 - ${t("creations.steps.fillInCreationParameters.stepTitle")}`])
            setActiveStep(0)
        }
    }, [draftId, creationId]);

    useEffect(() => {
        if (mediaTree && (draftDetails || creationDetails)) {
            mediaSize = 0;
            const media = draftDetails?.media || creationDetails!.media
            const mediaTreeCheckboxNodes = buildMediaTreeCheckboxNodes(mediaTree).filter(function filterOutNodeElements(checkboxTreeElement: any) {
                    if (checkboxTreeElement.showOnTree) return true

                    if (checkboxTreeElement.children) {
                        return (checkboxTreeElement.children = checkboxTreeElement.children.filter(filterOutNodeElements)).length
                    }
                }
            )
            setTimeout(() => {
                setNodes(mediaTreeCheckboxNodes)
                setFilteredNodes([{
                    label: 'Wszystkie nośniki',
                    value: 'all',
                    children: mediaTreeCheckboxNodes
                }] as any)
                setMediaLength(mediaSize)
            }, 200)

            setCheckedNodes(media.map(mediaElement => `${mediaElement.id}_Medium`) as any);

        }
    }, [mediaTree, draftDetails, creationDetails])


    useEffect(() => {
        if (draftId && draftDetails) {
            dispatch(setCreationForm({
                ...initialCreationFormState.creationForm,
                creationPropertiesAttributes: draftDetails.draftProperties.map((prop: PropertyAttribute) => {
                    let defaultVal;
                    switch (prop.propType) {
                        case 'string': {
                            defaultVal = ''
                            break;
                        }
                        case 'integer': {
                            defaultVal = 0
                            break;
                        }
                        case 'mediabank_resource': {
                            defaultVal = null
                            break;
                        }
                        default: {
                            defaultVal = false
                        }
                    }

                    return {
                        id: prop.id as number,
                        value: defaultVal
                    }
                })
            }));
            setSteps([`${t("creations.steps.step")} 1 - ${t("creations.steps.selectDraft")} (${draftDetails.name})`, `${t("creations.steps.step")} 2 - ${t("creations.steps.adjustCreationViewStep.stepTitle")}`, `${t("creations.steps.step")} 3 - ${t("creations.steps.fillInCreationParameters.stepTitle")}`])
            setActiveStep(1)
        }
    }, [draftDetails])

    useEffect(() => {
        if (creationId && creationDetails) {
            dispatch(setCreationForm({
                ...creationDetails,
                localisablesMediatablesAttributes: creationDetails.media.map((el) => {
                    return {localisableId: el.id, localisableType: 'Medium'}
                }),
                creationPropertiesAttributes: creationDetails.creationProperties.map((el) => {
                    return {
                        id: el.id,
                        value: el.value
                    }
                }),
                ownershipsAttributes: creationDetails.ownerships
            }))

            getResources((creationDetails.creationProperties.filter(prop => prop.propType === 'mediabank_resource' && prop.value)
                .map((el) => {
                    return {resourceId: el.value as number, propId: el.id!}
                }))).unwrap().then((res: MediabankResourceWithPropID[]) => {
                const selectedResources = res.map((el) => {
                    return {
                        propId: el.propId,
                        name: el.name,
                        resourceId: el.id,
                        thumbnailUrl: el.thumbnailUrl,
                        resourceUrl: el.thumbnailUrl
                    }
                })
                setSelectedMediabankResources(selectedResources)
            })


            setSteps([`${t("creations.steps.step")} 1 - ${t("creations.steps.adjustCreationViewStep.stepTitle")}`, `${t("creations.steps.step")} 2 - ${t("creations.steps.fillInCreationParameters.stepTitle")}`])
            setActiveStep(1)
        }
    }, [creationDetails]);

    useDebounce(() => {
            // Reset nodes back to unfiltered state
            if (!filterNodesText) {
                setFilteredNodes([{
                    label: 'Wszystkie nośniki',
                    value: 'all',
                    children: nodes
                }] as any);
                return;
            }
            setFilteredNodes(
                [{
                    label: 'Wszystkie nośniki',
                    value: 'all',
                    children: nodes.reduce(filterNodes, [])
                }] as any)
            checkboxTreeRef.current.onExpandAll()
        }, [filterNodesText], 100
    );

    const deleteCreationMutation = async () => {
        try {
            setOpenRemoveCreationDialog(false);
            const result = await deleteCreation(creationId).unwrap()
            enqueueSnackbar('Pomyślnie usunięto kreacje', {variant: 'success'});
            navigate('/creations')
        } catch (err: any) {
            enqueueSnackbar('Wystąpił błąd', {variant: 'error'});
        }
    }

    const handleRemoveCreation = () => {
        deleteCreationMutation();
    };

    const actionButtons = (possibleEvents: PossibleEvent[]) => {
        return <Box sx={{display: 'flex', gap: '20px'}}>
            {possibleEvents.map((event: PossibleEvent) => {
                return <Button size={'small'} onClick={async () => {
                    try {
                        const result = await manageState({
                            id: creationDetails?.id || null,
                            possibleEvent: event
                        }).unwrap()
                        enqueueSnackbar('Pomyślnie zaktualizowano status', {variant: 'success'});
                    } catch (err: any) {
                        enqueueSnackbar('Wystąpił błąd', {variant: 'error'});
                    }
                }} variant={'contained'} color={'info'}>{t('creations.possibleEvent.' + event)}</Button>
            })}
        </Box>
    }

    const createTextProp = (creationFormProp: CreationProperty, draftProp: PropertyAttribute, textFieldType: HTMLInputTypeAttribute | undefined) => {
        return (<TextField
            key={draftProp.id}
            value={creationFormProp ? creationFormProp.value : ''}
            type={textFieldType}
            label={draftProp.name}
            onChange={(event) => {
                dispatch(setCreationForm({
                    ...creationForm,
                    creationPropertiesAttributes: creationForm.creationPropertiesAttributes.map((prop: CreationProperty) => {
                        if (creationFormProp.id === prop.id) {
                            return {
                                id: prop.id,
                                value: event.target.value
                            }
                        }
                        return prop
                    })
                }));
            }}
            variant="outlined"
            sx={{mb: 3}}
            fullWidth/>)
    }

    function openLink(url: string) {
        const link = document.createElement("a");
        link.href = url
        link.target = '_blank'
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link)
    }

    function openImageFile(mediabankResource: SelectedMediabankResource) {
        if (mediabankResource.resourceUrl) {
            openLink(mediabankResource.resourceUrl)
        } else {
            getFile(mediabankResource.resourceId).unwrap().then(async (res) => {
                if (res.data.resourceFileUrl) {
                    openLink(res.data.resourceFileUrl);
                }
            })
        }
    };

    const selectDraftStep =
        <Box sx={{paddingTop: '40px'}}>
            {!loadingDraftDetails ? (
                <DraftsList showHeader={false} showCrudActions={false} selectable={true} onRowClick={onSelectDraft}
                            activeDraftId={draftId ? draftDetails?.id : null}></DraftsList>
            ) : <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px'}}>
                <CircularProgress size={100}/>
            </Box>}
        </Box>

    const creationProperties = creationDetails ? creationDetails.creationProperties : draftDetails?.draftProperties

    const adjustCreationViewStep =
        <Box sx={{paddingTop: '40px', maxWidth: '60%'}}>
            {!loadingDraftDetails ? (
                <form autoComplete="off">
                    <TextField
                        value={creationForm.name}
                        label={t('creations.steps.adjustCreationViewStep.creationName')}
                        onChange={(event) => {
                            setFormErrors({...formErrors, name: false})
                            dispatch(setCreationForm({
                                ...creationForm,
                                name: event.target.value
                            }));
                        }}
                        required
                        variant="outlined"
                        sx={{mb: 3}}
                        fullWidth
                        error={formErrors.name}/>
                    {(creationForm.creationPropertiesAttributes.length && creationProperties) && creationProperties.map((draftProp: PropertyAttribute) => {
                            const creationFormProp = creationForm.creationPropertiesAttributes.filter(creationProp => creationProp.id === draftProp.id)[0]
                            let type: HTMLInputTypeAttribute | undefined;
                            let customProp;

                            switch (draftProp.propType) {
                                case 'string': {
                                    customProp = createTextProp(creationFormProp, draftProp, 'text')
                                    break;
                                }
                                case 'integer': {
                                    customProp = createTextProp(creationFormProp, draftProp, 'number')
                                    break;
                                }
                                case 'mediabank_resource': {
                                    const selectedMediabankResource = selectedMediabankResources.filter(el => el.propId === creationFormProp.id)[0]
                                    customProp = (<div key={draftProp.id}>
                                        <Typography
                                            sx={{color: 'text.primary', paddingY: '10px'}}>{draftProp.name}:</Typography>
                                        <Box sx={{display: 'flex', alignItems: 'center', paddingY: '10px', gap: '10px'}}>
                                            {
                                                selectedMediabankResource &&
                                                (!selectedMediabankResource.thumbnailUrl ? (
                                                        <DescriptionOutlinedIcon
                                                            fontSize="large"/>) : (
                                                        <Box sx={{
                                                            height: Thumbnail.width,
                                                            width: Thumbnail.width,
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            background: Thumbnail.background
                                                        }}><img
                                                            width={Thumbnail.width}
                                                            height={Thumbnail.width}
                                                            src={`${selectedMediabankResource.thumbnailUrl}`}
                                                            alt={selectedMediabankResource.name}
                                                            loading="lazy"/>
                                                        </Box>)
                                                )
                                            }
                                            {
                                                selectedMediabankResource && (
                                                    <Link onClick={(event) => {
                                                        event.preventDefault()
                                                        openImageFile(selectedMediabankResource)
                                                    }
                                                    }

                                                          component={'button'}>{selectedMediabankResource.name}</Link>
                                                )
                                            }
                                            {
                                                selectedMediabankResource && (
                                                    <IconButton color={'error'} aria-label="delete" onClick={() => {
                                                        setSelectedMediabankResources([...selectedMediabankResources.filter(el => el.propId !== selectedMediabankResource.propId)])
                                                        dispatch(setCreationForm({
                                                            ...creationForm,
                                                            creationPropertiesAttributes: creationForm.creationPropertiesAttributes.map((prop: CreationProperty) => {
                                                                if (creationFormProp.id === prop.id) {
                                                                    return {
                                                                        id: prop.id,
                                                                        value: null
                                                                    }
                                                                }
                                                                return prop
                                                            })
                                                        }));
                                                    }}>
                                                        <ClearIcon/>
                                                    </IconButton>
                                                )
                                            }
                                        </Box>
                                        <Button variant="contained"
                                                sx={{width: '100%', display: 'block', marginBottom: '15px'}}
                                                onClick={() => {
                                                    setOpenMediabankDialog(creationFormProp.id)
                                                }}>{'Wybierz plik..'}</Button>

                                        {openMediabankDialog && (<Dialog
                                            open={openMediabankDialog === creationFormProp.id}
                                            onClose={() => {
                                                setOpenMediabankDialog(null)
                                            }}
                                            aria-labelledby="alert-dialog-title"
                                            aria-describedby="alert-dialog-description"
                                        >
                                            <DialogTitle id="alert-dialog-title">
                                                Wybierz plik mediabanku
                                            </DialogTitle>
                                            <DialogContent>
                                                <Box sx={{width: 500, bgcolor: 'background.paper'}}>
                                                    <Breadcrumbs aria-label="breadcrumb">
                                                        {breadcrumbsForMediabankDialog.length && breadcrumbsForMediabankDialog.map((breadcrumb, index) => {
                                                            return index !== breadcrumbsForMediabankDialog.length - 1 ? (
                                                                <Link key={breadcrumb.dirName} underline="hover"
                                                                      color="inherit"
                                                                      onClick={() => {
                                                                          setParentIdForMediabankDialog(breadcrumb.resourceId)
                                                                          setBreadcrumbsForMediabankDialog(breadcrumbsForMediabankDialog.slice(0, index + 1))
                                                                      }} component="button">
                                                                    {breadcrumb.dirName}
                                                                </Link>
                                                            ) : (<Typography key={breadcrumb.dirName}
                                                                             sx={{color: 'text.primary'}}>{breadcrumb.dirName}</Typography>)
                                                        })}
                                                    </Breadcrumbs>
                                                    <List>
                                                        {
                                                            (resourcesDataForMediabankDialog) && (
                                                                resourcesDataForMediabankDialog.data
                                                                    .map((resource) => {
                                                                        return (<ListItem key={resource.id} disablePadding
                                                                                          selected={!!selectedMediabankResources.filter(el => el.resourceId === resource.id && el.propId === openMediabankDialog).length}
                                                                                          sx={{height: '50px'}}>
                                                                            {resource.resourceType === ResourceType.Dir ? (
                                                                                <ListItemButton onClick={() => {
                                                                                    setParentIdForMediabankDialog(resource.id.toString())
                                                                                    setBreadcrumbsForMediabankDialog([...breadcrumbsForMediabankDialog, {
                                                                                        resourceId: resource.id.toString(),
                                                                                        dirName: resource.name
                                                                                    }])
                                                                                }}>
                                                                                    <ListItemIcon>
                                                                                        <FolderOutlinedIcon/>
                                                                                    </ListItemIcon>
                                                                                    <ListItemText primary={resource.name}/>
                                                                                </ListItemButton>) : (
                                                                                <ListItemButton onClick={() => {
                                                                                    dispatch(setCreationForm({
                                                                                        ...creationForm,
                                                                                        creationPropertiesAttributes: creationForm.creationPropertiesAttributes.map((prop: CreationProperty) => {
                                                                                            if (creationFormProp.id === prop.id) {
                                                                                                return {
                                                                                                    id: prop.id,
                                                                                                    value: resource.id
                                                                                                }
                                                                                            }
                                                                                            return prop
                                                                                        })
                                                                                    }));
                                                                                    setSelectedMediabankResources([...selectedMediabankResources.filter(el => el.propId !== creationFormProp.id), {
                                                                                        propId: creationFormProp.id!,
                                                                                        resourceId: resource.id,
                                                                                        name: resource.name,
                                                                                        thumbnailUrl: resource.thumbnailUrl,
                                                                                        resourceUrl: resource.resourceFileUrl
                                                                                    }])
                                                                                    setOpenMediabankDialog(null)
                                                                                }}>
                                                                                    <ListItemIcon>
                                                                                        {
                                                                                            !resource.thumbnailUrl ? (
                                                                                                <DescriptionOutlinedIcon
                                                                                                    fontSize="large"/>) : (
                                                                                                <Box sx={{
                                                                                                    height: Thumbnail.width,
                                                                                                    width: Thumbnail.width,
                                                                                                    display: 'flex',
                                                                                                    alignItems: 'center',
                                                                                                    justifyContent: 'center',
                                                                                                    background: Thumbnail.background
                                                                                                }}><img
                                                                                                    width={Thumbnail.width}
                                                                                                    height={Thumbnail.width}
                                                                                                    src={`${resource.thumbnailUrl}`}
                                                                                                    alt={resource.name}
                                                                                                    loading="lazy"/>
                                                                                                </Box>)
                                                                                        }
                                                                                    </ListItemIcon>
                                                                                    <ListItemText primary={resource.name}/>
                                                                                </ListItemButton>)}
                                                                        </ListItem>)
                                                                    })
                                                            )
                                                        }

                                                        {(resourcesDataForMediabankDialog && resourcesDataForMediabankDialog.data.length === 0) && (
                                                            <Typography
                                                                sx={{color: 'text.primary', paddingY: '10px'}}>Brak
                                                                plików...</Typography>)
                                                        }
                                                    </List>
                                                </Box>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={() => {
                                                    setOpenMediabankDialog(null)
                                                }} autoFocus>
                                                    {t('common.cancel')}
                                                </Button>
                                            </DialogActions>
                                        </Dialog>)}


                                    </div>)
                                    break;
                                }
                                default: {
                                    customProp = (<FormControlLabel key={draftProp.id} sx={{paddingBottom: '15px'}}
                                                                    onChange={(_, checked) => {
                                                                        dispatch(setCreationForm({
                                                                            ...creationForm,
                                                                            creationPropertiesAttributes: creationForm.creationPropertiesAttributes.map((prop: CreationProperty) => {
                                                                                if (creationFormProp.id === prop.id) {
                                                                                    return {
                                                                                        id: prop.id,
                                                                                        value: checked
                                                                                    }
                                                                                }
                                                                                return prop
                                                                            })
                                                                        }));
                                                                    }} control={<Checkbox/>} label={draftProp.name}/>)
                                }
                            }
                            return customProp
                        }
                    )}

                </form>
            ) : <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px'}}>
                <CircularProgress size={100}/>
            </Box>}
        </Box>


    const addAuthorizedPerson = () => {
        if (!authorizedPerson) {
            return
        }

        setOpenAddAuthorizedPersonModal(false)
        dispatch(setCreationForm({
            ...creationForm,
            ownershipsAttributes: [...creationForm.ownershipsAttributes, {
                userId: authorizedPerson,
                accessLevel: authorizedPersonRights
            }]
        }));
        setAuthorizedPerson(null)
    }

    const getNextButtonTitle = () => {
        if (activeStep === 2 && creationId) {
            return t("creations.updateCreation");
        }
        if (activeStep === 2) {
            return t("creations.createCreation");
        }
        return t("common.next");
    }

    const fillInParametersStep = <Box sx={{paddingTop: '40px'}}>
        <form autoComplete="off">
            <Box sx={{maxWidth: '60%'}}>
                <Button disabled={mediaTree?.length === 0} variant="contained"
                        fullWidth={true}
                        onClick={() => {
                            setOpenMediaTreeModal(true)
                        }} sx={{marginBottom: '20px', textAlign: 'left'}}>
                    {t('draftForm.mediaPicker')}&nbsp;
                    {(mediaTree?.length === 0) ? <CircularProgress sx={{marginLeft: '10px'}}
                                                                   size={20}/> : `${checkedNodes.length}/${mediaLength || 0}`}</Button>
                <Dialog
                    open={openMediaTreeModal}
                    onClose={handleCloseNodesModal}
                    fullWidth={true}
                    maxWidth="md"
                >

                    <DialogTitle id="alert-dialog-title">
                        {t("draftForm.chooseMedia")}
                    </DialogTitle>
                    <DialogContent>

                        {(loadingMediaTree) && (
                            <Box
                                sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px'}}>
                                <CircularProgress size={100}/>
                            </Box>)}

                        <Box sx={{minHeight: '300px'}}>
                            {mediaTree && (
                                <><TextField
                                    value={filterNodesText}
                                    label="Wyszukaj..."
                                    onInput={onFilterNodeChange}
                                    variant="outlined"
                                    sx={{mt: 1, mb: 3}}
                                    fullWidth
                                />
                                    <CheckboxTree
                                        ref={checkboxTreeRef}
                                        key={treeState.key}
                                        showNodeTitles={true}
                                        nodes={filteredNodes}
                                        checked={checkedNodes}
                                        expanded={expanded}
                                        showExpandAll={true}
                                        onCheck={(checked: any) => {
                                            setCheckedNodes(checked)
                                            setTreeState({key: Math.random()})
                                        }}
                                        onExpand={(expanded: any) => {
                                            setExpanded(expanded)
                                        }}
                                    /></>
                            )}
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseNodesModal}>{t('common.closeWindow')}</Button>
                    </DialogActions>
                </Dialog>
                <Box sx={{display: 'flex', gap: '40px', alignItems: 'center'}}>
                    <Box sx={{width: '100%'}}>
                        <DateTimePicker
                            value={dayjs(creationForm.startAt)}
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    variant: 'outlined',
                                    error: formErrors.startAt
                                },
                            }}
                            label={t('creations.steps.fillInCreationParameters.emissionStart')}
                            onChange={(event) => {
                                setFormErrors({...formErrors, startAt: false})
                                dispatch(setCreationForm({
                                    ...creationForm,
                                    startAt: dayjs(event).format()
                                }))
                            }}/></Box>
                    <span>-</span>
                    <Box sx={{width: '100%'}}>
                        <DateTimePicker
                            value={dayjs(creationForm.endAt)}
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    variant: 'outlined',
                                    error: formErrors.startAt
                                },
                            }}
                            label={t('creations.steps.fillInCreationParameters.emissionEnd')}
                            onChange={(event) => {
                                setFormErrors({...formErrors, endAt: false})
                                dispatch(setCreationForm({
                                    ...creationForm,
                                    endAt: dayjs(event).format()
                                }))
                            }}/></Box>
                </Box>
                <FormControl fullWidth sx={{marginTop: '25px'}}>
                    <InputLabel shrink id="demo-simple-select-label">Pora dnia</InputLabel>
                    <Select
                        variant={"outlined"}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Pora dnia"
                        value={creationForm.timeOfDay || ''}
                        notched
                        onChange={(event) => {
                            dispatch(setCreationForm({
                                ...creationForm,
                                timeOfDay: event.target.value
                            }));
                        }}
                    >
                        <MenuItem value="">
                            <em>Brak</em>
                        </MenuItem>
                        {cmEnums && cmEnums.find(cmEnum => cmEnum.name === 'timeOfDays')?.values.map((timeOfDay) => (
                            <MenuItem
                                key={timeOfDay}
                                value={timeOfDay}>{t(`creations.steps.fillInCreationParameters.timeOfDays.${timeOfDay}`)}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth sx={{marginTop: '25px'}}>
                    <InputLabel shrink id="demo-simple-select-label">Intensywność</InputLabel>
                    <Select
                        variant={"outlined"}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Intensywność"
                        value={creationForm.intensity || ''}
                        notched
                        onChange={(event) => {
                            dispatch(setCreationForm({
                                ...creationForm,
                                intensity: event.target.value
                            }));
                        }}
                    >
                        <MenuItem value="">
                            <em>Brak</em>
                        </MenuItem>
                        {cmEnums && cmEnums.find(cmEnum => cmEnum.name === 'intensities')?.values.map((intensity) => (
                            <MenuItem
                                key={intensity}
                                value={intensity}>{t(`creations.steps.fillInCreationParameters.intensities.${intensity}`)}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth sx={{marginTop: '25px'}}>
                    <InputLabel shrink id="demo-simple-select-label">Pogoda</InputLabel>
                    <Select
                        variant={"outlined"}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Intensywność"
                        value={creationForm.weather || ''}
                        notched
                        onChange={(event) => {
                            dispatch(setCreationForm({
                                ...creationForm,
                                weather: event.target.value
                            }));
                        }}
                    >
                        <MenuItem value="">
                            <em>Brak</em>
                        </MenuItem>
                        {cmEnums && cmEnums.find(cmEnum => cmEnum.name === 'weathers')?.values.map((weather) => (
                            <MenuItem
                                key={weather}
                                value={weather}>{t(`creations.steps.fillInCreationParameters.weathers.${weather}`)}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Box sx={{display: 'flex', alignItems: 'baseline'}}>
                    <Autocomplete
                        PopperComponent={(props) => <Popper {...props} style={{display: 'none'}}/>}
                        className={'AuthorizedPersons_chip-list'}
                        sx={{width: '100%', marginTop: '20px'}}
                        multiple
                        id="tags-outlined"
                        options={[]}
                        value={creationForm.ownershipsAttributes.map(el => el.userId.toString())}
                        isOptionEqualToValue={() => true}
                        getOptionLabel={(option) => option}
                        filterSelectedOptions
                        renderTags={(tagValue) => {
                            return tagValue.map(val => <Chip
                                label={responsiblePersons!.filter(el => el.id === parseInt(val))[0].email + ' | ' + t(`creations.steps.fillInCreationParameters.accessLevel.${creationForm.ownershipsAttributes.filter(el => el.userId.toString() === val)[0].accessLevel}`)}
                                onDelete={(tag) => {
                                    dispatch(setCreationForm({
                                        ...creationForm,
                                        ownershipsAttributes: creationForm.ownershipsAttributes.filter(person => person.userId !== parseInt(val))
                                    }));
                                }}
                            />)
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                onClick={(e) => {
                                    if (e.target instanceof SVGElement || e.target instanceof HTMLElement) {
                                        const classList = Array.from(e.target.classList)
                                        if (classList.length === 0 || classList.some(element => element.includes('fontSizeSmall') || classList.some(element => element.includes('clearIndicator')))) {
                                            dispatch(setCreationForm({
                                                ...creationForm,
                                                ownershipsAttributes: []
                                            }));
                                            return
                                        }
                                    }
                                    setOpenAddAuthorizedPersonModal(true)
                                }
                                }
                                label="Dodaj uprawnienia"
                                placeholder="Uprawniona osoba"
                            />
                        )}
                    />
                    <Dialog
                        open={openAddAuthorizedPersonModal}
                        onClose={() => {
                            setOpenAddAuthorizedPersonModal(false)
                        }}
                        fullWidth={true}
                        maxWidth="md"
                    >

                        <DialogTitle id="alert-dialog-title">
                            Dodaj osobe uprawnioną
                        </DialogTitle>
                        <DialogContent>
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                {responsiblePersons && (<Autocomplete
                                    getOptionLabel={(value) => value.email}
                                    onChange={(_, value) => setAuthorizedPerson(value?.id || null)}
                                    options={responsiblePersons}
                                    sx={{width: 300, marginTop: '10px'}}
                                    renderInput={(params) => <TextField {...params} label="Osoba uprawniona"/>}
                                />)}
                                <FormControl sx={{marginLeft: '10px', marginTop: '10px'}}>
                                    <InputLabel id="demo-simple-select-label">Uprawnienia</InputLabel>
                                    <Select
                                        sx={{width: '150px'}}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={authorizedPersonRights}
                                        label="Uprawnienia"
                                        onChange={(e) => {
                                            setAuthorizedPersonRights(e.target.value as string)
                                        }}
                                    >
                                        <MenuItem value={'write'}>Edycja</MenuItem>
                                        <MenuItem value={'read'}>Wyświetlanie</MenuItem>
                                    </Select>
                                </FormControl>

                            </Box>

                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    setOpenAddAuthorizedPersonModal(false)
                                }}>{t('common.closeWindow')}</Button>
                            <Button
                                disabled={!authorizedPerson}
                                variant={'contained'} onClick={() => {
                                addAuthorizedPerson()
                            }}>Dodaj</Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            </Box>

        </form>
    </Box>

    return (
        <>
            <Backdrop
                sx={(theme) => ({color: '#fff', zIndex: theme.zIndex.drawer + 1})}
                open={deletingCreation}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Box className={'page-header'}>
                <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'baseline'}}>
                    <h1>{creationId ? t("creations.editCreation") + " " + (creationDetails ? creationDetails?.name : '') : t("creations.newCreationCreator")}</h1>
                    {creationId && (<>
                        <Button onClick={() => {
                            setOpenRemoveCreationDialog(true)
                        }} variant={'outlined'} color={'error'}><Typography> {t('creations.removeCreation')} </Typography></Button>
                    </>)}
                </Box>
                {creationDetails &&  <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px', marginTop: '20px'}}>
                    <CmStatusIconBox state={creationDetails.state}></CmStatusIconBox>
                    {actionButtons(creationDetails.possibleEvents)}
                </Box>}
            </Box>
            <Box sx={{width: '100%', paddingTop: '20px'}}>
                <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => {
                        const stepProps: { completed?: boolean } = {};
                        const labelProps: {
                            optional?: React.ReactNode;
                        } = {};
                        if (isStepSkipped(index)) {
                            stepProps.completed = false;
                        }
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                <>
                    {activeStep === 0 && (selectDraftStep)}

                    {activeStep === 1 && (adjustCreationViewStep)}

                    {activeStep === 2 && (fillInParametersStep)}

                    <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                        {((activeStep > 0 && !creationId) || (activeStep > 1 && creationId)) && (<Button
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{mr: 1}}
                        >
                            {t("common.back")}
                        </Button>)
                        }
                        <Box sx={{flex: '1 1 auto'}}/>
                        <Button onClick={handleNext} disabled={disableNextButton}>
                            {getNextButtonTitle()}
                        </Button>
                    </Box>
                </>
            </Box>

            {openRemoveCreationDialog && (<Dialog
                open={openRemoveCreationDialog}
                onClose={() => {
                    setOpenRemoveCreationDialog(false);
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Usuwanie kreacji
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {'Usunąć kreacje ' + creationDetails?.name + '?'}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant={'contained'} onClick={() => {
                        handleRemoveCreation()
                    }}>{t('common.remove')}</Button>
                    <Button onClick={() => {
                        setOpenRemoveCreationDialog(false);
                    }} autoFocus>
                        {t('common.cancel')}
                    </Button>
                </DialogActions>
            </Dialog>)}
        </>
    );
};

export default CreationForm;
