import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useSnackbar} from 'notistack';
import {FilterItemChip} from "../../drafts/types";
import {
    BreadCrumb, initialCreationFormState,
    selectBreadCrumbs,
    selectCurrentMediabankResourcesFiltersState,
    setBreadCrumbs,
    setMediabankFilters
} from "../store/mediabankResourcesSlice";
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import {
    useChangeResourceLocationMutation, useChangeResourceNameMutation,
    useCreateResourceMutation,
    useGetMediabankResourcesForSelectQuery,
    useGetMediabankResourcesQuery,
    useLazyGetMediabankResourceQuery
} from "../api/mediabankResourcesApiSlice";
import {
    alpha,
    Autocomplete,
    Box,
    Breadcrumbs,
    Button,
    ButtonGroup,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Menu,
    TableSortLabel,
    TextField,
    Toolbar,
    Typography
} from "@mui/material";
import {Clear} from "@mui/icons-material";
import Chip from "@mui/material/Chip";
import useDebounce from "../../../hooks/useDebounce";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {
    ChangeMediabankResourceNamePayload,
    ChangeResourceLocationPayload,
    MediabankFile,
    MediabankResource,
    MediabankResourcesFilters, MediabankResourceTableRow,
    ResourcePayload,
    ResourceType
} from "../types";
import {visuallyHidden} from '@mui/utils';
import {useGetCmTagsQuery} from "../../../app/appApiSlice";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import {useTranslation} from "react-i18next";
import Link from "@mui/material/Link";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {VisuallyHiddenInput} from "../../../config/visuallyHiddenInput";
import UseApiV1 from "../../../hooks/useApiV1";
import {ApiTags, baseApi} from "../../../app/api/baseApi";
import DialogContentText from "@mui/material/DialogContentText";
import {Thumbnail} from "../../../config/images";

type Order = 'asc' | 'desc';

interface EnhancedTableToolbarProps {
    numSelected: number;
}

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof MediabankResourceTableRow) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof MediabankResourceTableRow;
    label: string;
    numeric: boolean;
    sort: boolean;
}

const MediaList = () => {
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const location = useLocation();

    const headCells: readonly HeadCell[] = [
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Nazwa folderu',
            sort: true
        },
        {
            id: 'byteSize',
            numeric: false,
            disablePadding: true,
            label: 'Rozmiar',
            sort: true
        },
        {
            id: 'metadata',
            numeric: false,
            disablePadding: true,
            label: 'Rozdzielczość',
            sort: false
        },
        {
            id: 'tagList',
            numeric: true,
            disablePadding: false,
            label: 'Tagi',
            sort: false
        },
        {
            id: 'updatedAt',
            numeric: true,
            disablePadding: false,
            label: 'Data wgrania',
            sort: true
        },
        {
            id: 'actions',
            numeric: false,
            disablePadding: false,
            label: '',
            sort: false
        }
    ];

    const currentFiltersState = useSelector(selectCurrentMediabankResourcesFiltersState);
    const breadCrumbs = useSelector(selectBreadCrumbs)
    const [searchByName, setSearchByName] = React.useState<string>('');
    const [newDirectoryName, setNewDirectoryName] = React.useState<string>('');
    const [tagsForResource, setTagsForResource] = React.useState<string[]>([]);
    const [selected, setSelected] = React.useState<readonly number[]>([]);
    const [files, setFiles] = useState<MediabankFile[]>([]);
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof MediabankResourceTableRow>('name');
    const [openAddResource, setOpenAddResource] = React.useState<ResourceType | null>(null);
    const [openAssignTagsToFile, setOpenAssignTagsToFile] = React.useState<MediabankFile[]>([]);
    const [openRemoveFileConfirmationModal, setOpenRemoveFileConfirmationModal] = React.useState<boolean>(false);
    const [openMoveResourcesDialog, setOpenMoveResourcesDialog] = React.useState<boolean>(false);
    const [parentIdForMoveResources, setParentIdForMoveResources] = React.useState<string | null>(null);
    const [breadcrumbsForMoveResources, setBreadcrumbsForMoveResources] = React.useState<BreadCrumb[]>([]);
    const [creatingResource, setCreatingResource] = React.useState<boolean>(false);
    const [anchorElForActionMenu, setAnchorElForActionMenu] = React.useState<null | HTMLElement>(null);
    const [activeRow, setActiveRow] = React.useState<number | null>(null);
    const [openChangeResourceNameMenu, setOpenChangeResourceNameMenu] = React.useState<number | null>(null);
    const [openChangeResourceNameDialog, setOpenChangeResourceNameDialog] = useState<number | null>()
    const [newResourceName, setNewResourceName] = React.useState<string>('');

    const openActionMenu = Boolean(anchorElForActionMenu);

    const handleOpenAddResource = (resourceType: ResourceType) => setOpenAddResource(resourceType);

    function resetAddResourceState() {
        setOpenAddResource(null)
        setFiles([])
        setNewDirectoryName('')
        setTagsForResource([])
    }

    const handleCloseAddResource = () => {
        resetAddResourceState();
    };
    useEffect(() => () => {
        dispatch(setMediabankFilters(initialCreationFormState.filters))
        dispatch(setBreadCrumbs(initialCreationFormState.resourceBreadcrumbs))
    }, []);


    const {t} = useTranslation();
    const [createResource] = useCreateResourceMutation()
    const [changeResourceLocation, {isLoading: changingResourceLocation}] = useChangeResourceLocationMutation()
    const [changeResourceNameMutation, {isLoading: changingResourceName}] = useChangeResourceNameMutation()

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof MediabankResourceTableRow,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked && mediabankResources) {
            const newSelected = mediabankResources?.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleOpenMoveResource = () => {
        setParentIdForMoveResources(null)
        setOpenMoveResourcesDialog(true);
    }

    const addResource = async (resourceType: ResourceType) => {
        if (resourceType === ResourceType.Dir) {
            if (!newDirectoryName) {
                return
            }
            try {
                setCreatingResource(true)
                const resourcePayload: ResourcePayload = {
                    name: newDirectoryName,
                    resourceType: ResourceType.Dir,
                    tagList: tagsForResource
                };
                const result = await createResource(currentFiltersState.parentIdEq ? {
                    ...resourcePayload,
                    parentId: currentFiltersState.parentIdEq.toString()
                } : resourcePayload).unwrap()
                setCreatingResource(false)
                resetAddResourceState();
            } catch (err: any) {
                resetAddResourceState();
                setCreatingResource(false)
                enqueueSnackbar('Wystąpił błąd', {variant: 'error'});
            }
        } else {
            if (files.length === 0) {
                return
            }
            setCreatingResource(true)
            const xhr = new XMLHttpRequest();
            xhr.onreadystatechange = function () {
                if (xhr.readyState === XMLHttpRequest.DONE) {
                    const responseData = JSON.parse(xhr.responseText)
                    resetAddResourceState();
                    dispatch(baseApi.util.invalidateTags([ApiTags.MediabankResourcesList]));
                    setCreatingResource(false)
                }
            }

            xhr.open('POST', `${process.env.REACT_APP_DSP_BACKEND_API + UseApiV1('mediabank_resources_bulk')}`);
            let token = ''
            if (localStorage.getItem("user")) {
                token = JSON.parse(localStorage.getItem("user") || '').accessToken;
            }
            if (token) {
                xhr.setRequestHeader("authorization", `Bearer ${token}`);
            }
            let bodyFormData = new FormData();
            files.forEach((file, fileObjindex) => {
                bodyFormData.append(`mediabank_resources[][resource_file]`, file.file);
                bodyFormData.append(`mediabank_resources[][parent_id]`, currentFiltersState.parentIdEq!.toString());
                file.tagList.forEach((tag, tagIndex) => {
                    bodyFormData.append(`mediabank_resources[][tag_list][]`, tag);
                })
            })
            xhr.send(bodyFormData)
        }
    }


    let params = [];
    let filterItemChips: FilterItemChip[] = []
    for (const queryKey in currentFiltersState) {
        const value = currentFiltersState[queryKey as keyof typeof currentFiltersState]
        if (Array.isArray(value)) {
            value.forEach(item => {
                params.push(`q[${queryKey}][]=${item.toString()}`)
                filterItemChips.push({name: queryKey, value: item, isArray: true})
            })
        } else if (value) {
            params.push(`q[${queryKey}]=${value.toString()}`)
            filterItemChips.push({name: queryKey, value: value.toString(), isArray: false})
        }
    }


    const {isLoading, isError, isSuccess, data} =
        useGetMediabankResourcesQuery(params.join('&').toString());

    const {isLoading: loadingResourcesForMovingFiles, data: resourcesDataForMovingFiles} =
        useGetMediabankResourcesForSelectQuery(parentIdForMoveResources, {skip: breadcrumbsForMoveResources.length === 0});

    const {isLoading: loadingTags, isError: tagsReqError, isSuccess: fetchTagsSucccess, data: tags} =
        useGetCmTagsQuery()

    const [getFile, {data: FileData}] = useLazyGetMediabankResourceQuery()

    const mediabankResources = data?.data;

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const visibleRows = React.useMemo(
        () =>
            mediabankResources ? [...mediabankResources]
                .sort(getComparator(order, orderBy)) : [],
        [order, orderBy, mediabankResources]
    );


    function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator<Key extends keyof any>(order: Order, orderBy: Key,): (a: any, b: any) => number {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }


    useDebounce(() => {
            dispatch(setMediabankFilters({
                ...currentFiltersState,
                nameCont: searchByName
            }))
        }, [searchByName], 800
    );

    function removeFilter(filterElement: FilterItemChip) {
        if (filterElement.isArray) {
            dispatch(setMediabankFilters({
                ...currentFiltersState,
                [filterElement.name]: (currentFiltersState[filterElement.name as keyof MediabankResourcesFilters] as string[]).filter(el => el !== filterElement.value)
            }))
        } else {
            if (filterElement.name === 'nameCont') {
                setSearchByName('');
            }
            dispatch(setMediabankFilters({
                ...currentFiltersState,
                [filterElement.name]: ''
            }))
        }
    }

    const handleCheck = (event: React.MouseEvent<unknown>, id: number) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: readonly number[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const uploadedFiles = [...Array.from(e.target.files).map((el) => {
                return {
                    file: el,
                    tagList: []
                }
            })]
            setOpenAssignTagsToFile(uploadedFiles)
            setFiles([...files, ...uploadedFiles]);
        }
    };

    async function removeResources() {
        try {
            const changeResourceLocationPayload: ChangeResourceLocationPayload[] = selected.map((id) => {
                return {
                    id,
                    parentId: -1
                }
            })
            const result = await changeResourceLocation(changeResourceLocationPayload).unwrap()
            setOpenRemoveFileConfirmationModal(false)
            setSelected([])
        } catch (err: any) {
            enqueueSnackbar('Wystąpił błąd', {variant: 'error'});
            setOpenRemoveFileConfirmationModal(false)
        }
    }

    async function changeResourceName() {
        try {
            const changeResourceNamePayload: ChangeMediabankResourceNamePayload = {
                name: newResourceName,
                resourceId: openChangeResourceNameDialog as number
            }
            const result = await changeResourceNameMutation(changeResourceNamePayload).unwrap()
            setOpenChangeResourceNameDialog(null)
            setActiveRow(null)
        } catch (err: any) {
            enqueueSnackbar(`Wystąpił błąd`, {variant: 'error'});
            setOpenChangeResourceNameDialog(null)
            setActiveRow(null)
        }
    }

    async function moveResources(parentId: number | null) {
        try {
            const changeResourceLocationPayload: ChangeResourceLocationPayload[] = selected.map((id) => {
                return {
                    id,
                    parentId
                }
            })
            const result = await changeResourceLocation(changeResourceLocationPayload).unwrap()
            setSelected([])
            setOpenMoveResourcesDialog(false)
            setBreadcrumbsForMoveResources([])
        } catch (err: any) {
            enqueueSnackbar(`Wystąpił błąd ${err.status === 422 ? '- Operacja niedozwolona' : ''}`, {variant: 'error'});
            setOpenMoveResourcesDialog(false)
            setBreadcrumbsForMoveResources([])
        }
    }


    function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
        const {numSelected} = props;
        return (
            <Toolbar
                sx={[
                    {
                        pl: {sm: 2},
                        pr: {xs: 1, sm: 1},
                    },
                    numSelected > 0 && {
                        bgcolor: (theme) =>
                            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                    },
                ]}
            >
                {numSelected > 0 && (
                    <Typography
                        sx={{flex: '1 1 100%'}}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        {numSelected} zaznaczono
                    </Typography>
                )}
                {numSelected > 0 && (
                    <>
                        <Button sx={{width: '220px'}} variant={'text'}
                                onClick={() => {
                                    handleOpenMoveResource()
                                }}>
                            Przenieś pliki ({selected.length})</Button>
                        <Button sx={{width: '100px'}} variant={'text'} color="error" onClick={() => {
                            setOpenRemoveFileConfirmationModal(true)
                        }}>Usuń ({selected.length})</Button>
                    </>
                )}
            </Toolbar>
        );
    }

    function EnhancedTableHead(props: EnhancedTableProps) {
        const {onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort} =
            props;
        const createSortHandler =
            (property: keyof MediabankResourceTableRow) => (event: React.MouseEvent<unknown>) => {
                onRequestSort(event, property);
            };

        return (
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{
                                'aria-label': 'select all desserts',
                            }}
                        />
                    </TableCell>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            {headCell.sort ? (<TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>) : headCell.label}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    const removeFile = (file: MediabankFile) => {
        setFiles(files.filter(el => el !== file))
    };

    const assignTagsToFiles = () => {
        setFiles(files.map((file) => {
            const fileToAssign = openAssignTagsToFile.find(newFile => newFile === file)
            if (fileToAssign) {
                return {...fileToAssign, tagList: tagsForResource}
            }
            return file;
        }))
        setTagsForResource([])
        setOpenAssignTagsToFile([])
    }

    async function toDataURL(url: string) {
        const blob = await fetch(url).then(res => res.blob());
        return URL.createObjectURL(blob);
    }

    async function openImageFile(id: number) {
        getFile(id).unwrap().then(async (res) => {
            if (res.data.resourceFileUrl) {
                const link = document.createElement("a");
                link.href = res.data.resourceFileUrl
                link.target = '_blank'
                link.download = res.data.name;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link)
            }
        })
    };

    const getResourceImage = (row: MediabankResource) => {
        if (row.resourceType === ResourceType.Dir) {
            return <FolderOutlinedIcon fontSize="large"/>
        }

        if (row.resourceType === ResourceType.File && !row.thumbnailUrl) {
            return <DescriptionOutlinedIcon fontSize="large"/>
        }

        return <Box sx={{
            height: Thumbnail.width,
            width: Thumbnail.width,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: Thumbnail.background
        }}><img
            width={Thumbnail.width}
            src={`${row.thumbnailUrl}`}
            alt={row.name}
            loading="lazy"/>
        </Box>
    }

    return (

        <Box className="MediaList">
            <Box className={'page-header'}
                 sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'baseline'}}>
                <h1>Mediabank</h1>
            </Box>

            {(isLoading) && (
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px'}}>
                    <CircularProgress size={100}/>
                </Box>)}

            {(mediabankResources) && (<>
                <Grid container spacing={2} alignItems="center" paddingY={'20px'}>
                    <Grid item xs={4} alignItems="baseline">
                        <TextField
                            size="small"
                            value={searchByName}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setSearchByName(event.target.value);
                            }}
                            InputProps={{
                                endAdornment: <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => {
                                        setSearchByName('')
                                        dispatch(setMediabankFilters({
                                            ...currentFiltersState,
                                            nameCont: searchByName
                                        }))
                                    }
                                    }
                                    edge="end"
                                >
                                    {<Clear/>}
                                </IconButton>,
                            }}
                            variant={"outlined"}
                            required
                            fullWidth
                            id="mediabankResourceName"
                            label="Nazwa pliku lub folderu"
                            name="mediabankResourceName"
                            autoFocus></TextField>

                    </Grid>
                    {tags && (<Grid item xs={6} alignItems="baseline">
                        <FormControl fullWidth>
                            <InputLabel id="demo-multiple-chip-label">Tagi</InputLabel>
                            <Select
                                size="small"
                                labelId="demo-multiple-chip-label"
                                id="demo-multiple-chip"
                                multiple
                                value={currentFiltersState.tagsNameIn}
                                onChange={(event) => {
                                    dispatch(setMediabankFilters({
                                        ...currentFiltersState,
                                        tagsNameIn: typeof event.target.value === "string" ? [event.target.value] : event.target.value
                                    }))
                                }}
                                input={<OutlinedInput id="select-multiple-chip" label="Chip"/>}

                                MenuProps={MenuProps}
                            >
                                {tags.map((tagsNameIn) => (
                                    <MenuItem
                                        key={tagsNameIn.name}
                                        value={tagsNameIn.name}
                                    >
                                        {tagsNameIn.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>)}
                </Grid>
                <Grid container spacing={2} pb={2}>
                    {filterItemChips.filter(el => el.name !== 'parentIdEq').map((filter) => {
                        return (
                            <Grid item key={filter.value}>
                                <Chip
                                    color="info"
                                    label={filter.name + ': ' + filter.value}
                                    onClick={() => {
                                    }}
                                    onDelete={() => removeFilter(filter)}
                                />
                            </Grid>)
                    })}
                </Grid>
                <Box sx={{display: 'flex', justifyContent: 'flex-end', paddingBottom: '10px'}}>
                    <Button onClick={() => {
                        handleOpenAddResource(ResourceType.Dir)
                    }} variant={'contained'}>Dodaj folder</Button>
                    {breadCrumbs.length > 1 && (<Button sx={{marginLeft: 3}} onClick={() => {
                        handleOpenAddResource(ResourceType.File)
                    }} variant={'contained'}>Dodaj plik</Button>)}
                </Box>
                <Breadcrumbs aria-label="breadcrumb">
                    {breadCrumbs.map((breadcrumb, index) => {
                        return index !== breadCrumbs.length - 1 ? (
                            <Link key={breadcrumb.dirName} underline="hover" color="inherit" onClick={() => {
                                dispatch(setMediabankFilters({
                                    ...currentFiltersState,
                                    parentIdEq: breadcrumb.resourceId
                                }))
                                dispatch(setBreadCrumbs(breadCrumbs.slice(0, index + 1)))
                                setSelected([])
                            }} component="button">
                                {breadcrumb.dirName}
                            </Link>
                        ) : (<Typography key={breadcrumb.dirName}
                                         sx={{color: 'text.primary'}}>{breadcrumb.dirName}</Typography>)
                    })}
                </Breadcrumbs>
                <EnhancedTableToolbar numSelected={selected.length}/>
                <TableContainer component={Paper}>
                    <Table sx={{minWidth: 650}} aria-label="simple table">
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={mediabankResources.length}
                        />
                        <TableBody>
                            {visibleRows
                                .map((row, index) => {
                                    const isItemSelected = selected.includes(row.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (<TableRow
                                        hover
                                        onClick={(event: React.ChangeEvent<any>) => {
                                            if (event.target.tagName === 'INPUT' ||
                                                event.target.classList.contains('action-menu') ||
                                                event.target.classList.contains('MuiBackdrop-root') ||
                                                event.target.classList.contains('MuiIconButton-root')) {
                                                return
                                            }
                                            if (row.resourceType === ResourceType.File) {
                                                openImageFile(row.id)
                                                return
                                            }
                                            dispatch(setMediabankFilters({
                                                ...currentFiltersState,
                                                parentIdEq: row.id.toString()
                                            }))
                                            dispatch(setBreadCrumbs([...breadCrumbs, {
                                                dirName: row.name,
                                                resourceId: row.id.toString()
                                            }]))
                                            setSelected([])
                                        }}
                                        role="row"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.id}
                                        selected={isItemSelected || row.id === activeRow}
                                        sx={{cursor: 'pointer'}}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                onClick={(event) => handleCheck(event, row.id)}
                                                checked={isItemSelected}
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell component="th" scope="row" id={labelId}>
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '10px'
                                            }}>{getResourceImage(row)}{row.name}
                                            </Box>
                                        </TableCell>
                                        <TableCell align="left">{row.byteSizeHumanized}</TableCell>
                                        <TableCell
                                            align="left">{row.metadata?.height && (`${row.metadata?.height} x ${row.metadata?.width} px`)}</TableCell>
                                        <TableCell align="right">{row.tagList.join(', ')}</TableCell>
                                        <TableCell align="right">{row.updatedAt}</TableCell>
                                        <TableCell>
                                            <IconButton aria-label="fingerprint" color="success"
                                                        id="long-button"
                                                        onClick={(actionEvent: React.MouseEvent<HTMLElement>) => {
                                                            actionEvent.preventDefault()
                                                            actionEvent.stopPropagation()
                                                            setActiveRow(row.id)
                                                            setOpenChangeResourceNameMenu(row.id)
                                                            setAnchorElForActionMenu(actionEvent.currentTarget);
                                                        }}
                                                        aria-controls={openActionMenu ? 'long-menu' : undefined}
                                                        aria-expanded={openActionMenu ? 'true' : undefined}
                                                        aria-haspopup="true">
                                                <MoreVertIcon className="action-menu"/>
                                            </IconButton>
                                            <Menu
                                                id="long-menu"
                                                MenuListProps={{
                                                    'aria-labelledby': 'long-button',
                                                }}
                                                anchorEl={anchorElForActionMenu}
                                                open={openChangeResourceNameMenu === row.id}
                                                onClose={() => {
                                                    setActiveRow(null)
                                                    setAnchorElForActionMenu(null);
                                                    setOpenChangeResourceNameMenu(null)
                                                }}
                                                slotProps={{
                                                    paper: {
                                                        style: {
                                                            maxHeight: ITEM_HEIGHT * 4.5,
                                                            width: '20ch',
                                                        },
                                                    },
                                                }}
                                            >
                                                <MenuItem onClick={(event) => {
                                                    event.preventDefault()
                                                    event.stopPropagation()
                                                    setAnchorElForActionMenu(null);
                                                    setOpenChangeResourceNameMenu(null)
                                                    setNewResourceName(row.name)
                                                    setOpenChangeResourceNameDialog(row.id)
                                                }}>
                                                    Zmień nazwę
                                                </MenuItem>

                                            </Menu>
                                        </TableCell>
                                    </TableRow>)
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>

                {mediabankResources.find(resource => resource.id === openChangeResourceNameDialog) && (<Dialog
                    open={!!mediabankResources.find(resource => resource.id === openChangeResourceNameDialog)}
                    onClose={() => {
                        setOpenChangeResourceNameDialog(null)
                        setActiveRow(null)
                        setNewResourceName('')
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Zmiana nazwy zasobu
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            value={newResourceName}
                            label="Nazwa zasobu"
                            onChange={(event) => {
                                setNewResourceName(event.target.value)
                            }}
                            required
                            variant="outlined"
                            sx={{my: 3}}
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={!newResourceName} variant={'contained'} onClick={() => {
                            changeResourceName()
                        }}>{t('common.changeName')}</Button>
                        <Button onClick={() => {
                            setActiveRow(null)
                            setOpenChangeResourceNameDialog(null)
                        }} autoFocus>
                            {t('common.cancel')}
                        </Button>
                    </DialogActions>
                </Dialog>)}


                {openAddResource && (<Dialog
                    open={!!openAddResource}
                    onClose={handleCloseAddResource}
                    sx={{
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: "100%",
                                maxWidth: openAddResource === ResourceType.Dir ? "30vw" : "60vw",  // Set your width here
                            },
                        },
                    }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {openAddResource === ResourceType.Dir ? 'Dodaj folder' : 'Dodaj plik'}
                    </DialogTitle>
                    <DialogContent>
                        {
                            openAddResource === ResourceType.Dir ?
                                (<><TextField
                                    value={newDirectoryName}
                                    label="Nazwa folderu"
                                    onChange={(event) => {
                                        setNewDirectoryName(event.target.value)
                                    }}
                                    required
                                    variant="outlined"
                                    sx={{my: 3}}
                                    fullWidth
                                />
                                    {tags && (<Autocomplete
                                        multiple
                                        id="tags-filled"
                                        options={tags.map((option) => option.name)}
                                        defaultValue={[]}
                                        onChange={(_, value) => {
                                            setTagsForResource(value)
                                        }}
                                        freeSolo
                                        renderTags={(value: readonly string[], getTagProps) =>
                                            value.map((option: string, index: number) => {
                                                const {key, ...tagProps} = getTagProps({index});
                                                return (
                                                    <Chip sx={{marginY: '10px'}} color={"info"} variant="filled"
                                                          label={option}
                                                          key={key} {...tagProps} />
                                                );
                                            })
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="filled"
                                                label="Tagi"
                                                placeholder="Wybierz z listy lub dodaj własny"
                                            />
                                        )}
                                    />)}</>) :
                                <>
                                    <Box sx={{display: 'flex', width: '100%', justifyContent: 'center', gap: '10px'}}>
                                        <Button
                                            component="label"
                                            role={undefined}
                                            variant="outlined"
                                            disabled={creatingResource}
                                            tabIndex={-1}
                                            startIcon={<CloudUploadIcon/>}
                                        >
                                            Wybierz pliki
                                            <VisuallyHiddenInput type="file"
                                                                 multiple={true}
                                                                 onChange={handleFileChange}/>
                                        </Button>
                                        <Button
                                            component="label"
                                            role={undefined}
                                            variant="outlined"
                                            disabled={files.length === 0 || creatingResource}
                                            onClick={() => {
                                                setOpenAssignTagsToFile(files)
                                            }}
                                            tabIndex={-1}
                                        >
                                            Przypisz tagi do plików
                                        </Button>
                                    </Box>
                                    {/*<Box sx={{ width: '100%', paddingY: '30px'}}>*/}
                                    {/*    <LinearProgress />*/}
                                    {/*</Box>*/}
                                    {files && (
                                        <TableContainer>
                                            {creatingResource.toString()}
                                            <Table sx={{width: '100%'}} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Nazwa pliku</TableCell>
                                                        <TableCell align="left">Tagi</TableCell>
                                                        <TableCell align="right"></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {Array.from(files).map((file) => (
                                                        <TableRow
                                                            key={file.file.name}
                                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                                        >
                                                            <TableCell component="th" scope="row"
                                                                       style={{width: '30%'}}>
                                                                {file.file.name}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row"
                                                                       style={{width: '60%'}}>
                                                                {tags && (<Autocomplete
                                                                    disabled={creatingResource}
                                                                    multiple
                                                                    id="tags-filled"
                                                                    options={tags.map((option) => option.name)}
                                                                    defaultValue={[]}
                                                                    value={file.tagList}
                                                                    onChange={(_, value) => {
                                                                        // setTagsForDirectory(value)
                                                                        setFiles([...files.map((mediabankFile) => {
                                                                            if (file.file === mediabankFile.file) {
                                                                                return {
                                                                                    ...mediabankFile,
                                                                                    tagList: value
                                                                                }
                                                                            }

                                                                            return mediabankFile
                                                                        })])
                                                                    }}
                                                                    freeSolo
                                                                    renderTags={(value: readonly string[], getTagProps) =>
                                                                        value.map((option: string, index: number) => {
                                                                            const {
                                                                                key,
                                                                                ...tagProps
                                                                            } = getTagProps({index});
                                                                            return (
                                                                                <Chip sx={{marginY: '10px'}}
                                                                                      color={"info"}
                                                                                      variant="filled"
                                                                                      label={option}
                                                                                      key={key} {...tagProps} />
                                                                            );
                                                                        })
                                                                    }
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            {...params}
                                                                            variant="filled"
                                                                            label="Tagi"
                                                                            placeholder="Wybierz z listy lub dodaj własny"
                                                                        />
                                                                    )}
                                                                />)}
                                                            </TableCell>
                                                            <TableCell align="right" style={{width: '10%'}}>
                                                                <ButtonGroup variant="text"
                                                                             aria-label="Basic button group">
                                                                    <Button disabled={creatingResource} color="error"
                                                                            onClick={() => {
                                                                                removeFile(file)
                                                                            }}>Usuń</Button>
                                                                </ButtonGroup>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    )
                                    }
                                </>
                        }</DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleCloseAddResource}>{t('common.closeWindow')}</Button>
                        <Button
                            disabled={openAddResource === ResourceType.Dir && !newDirectoryName || creatingResource}
                            variant={'contained'} onClick={() => {
                            addResource(openAddResource)
                        }}> {creatingResource ? (<><CircularProgress sx={{marginRight: '5px'}}
                                                                     size={20}/>
                            <span>Dodaj</span></>) : ('Dodaj')}</Button>
                    </DialogActions>
                </Dialog>)}


                {openAssignTagsToFile && (<Dialog
                    open={openAssignTagsToFile.length > 0}
                    onClose={() => {
                        setOpenAssignTagsToFile([])
                    }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Dodaj tagi do plików
                    </DialogTitle>
                    <DialogContent>
                        {tags && (<Autocomplete
                            multiple
                            id="tags-filled"
                            options={tags.map((option) => option.name)}
                            defaultValue={[]}
                            onChange={(_, value) => {
                                setTagsForResource(value)
                            }}
                            freeSolo
                            renderTags={(value: readonly string[], getTagProps) =>
                                value.map((option: string, index: number) => {
                                    const {key, ...tagProps} = getTagProps({index});
                                    return (
                                        <Chip sx={{marginY: '10px'}} color={"info"} variant="filled"
                                              label={option}
                                              key={key} {...tagProps} />
                                    );
                                })
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="filled"
                                    label="Tagi"
                                    placeholder="Wybierz z listy lub dodaj własny"
                                />
                            )}
                        />)}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setOpenAssignTagsToFile([])
                                setTagsForResource([])
                            }}>{t('common.closeWindow')}</Button>
                        <Button
                            variant={'contained'} onClick={() => {
                            assignTagsToFiles();
                        }}>Dodaj</Button>
                    </DialogActions>
                </Dialog>)}
                {openRemoveFileConfirmationModal && (<Dialog
                    open={openRemoveFileConfirmationModal}
                    onClose={() => {
                        setOpenRemoveFileConfirmationModal(false)
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Usuwanie zasobu
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Usunąć zaznaczone pliki?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant={'contained'} onClick={() => {
                            removeResources()
                        }}>{t('common.remove')}</Button>
                        <Button onClick={() => {
                            setOpenRemoveFileConfirmationModal(false)
                        }} autoFocus>
                            {t('common.cancel')}
                        </Button>
                    </DialogActions>
                </Dialog>)}

                {openMoveResourcesDialog && (<Dialog
                    open={openMoveResourcesDialog}
                    onClose={() => {
                        setOpenMoveResourcesDialog(false)
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Przenieś pliki ({selected.length})
                    </DialogTitle>
                    <DialogContent>
                        <Box sx={{width: 500, bgcolor: 'background.paper'}}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link key={'root'} underline="hover" color="inherit"
                                      onClick={() => {
                                          setBreadcrumbsForMoveResources([])
                                      }} component="button">
                                    Root
                                </Link>
                                {breadcrumbsForMoveResources.length && breadcrumbsForMoveResources.map((breadcrumb, index) => {
                                    return index !== breadcrumbsForMoveResources.length - 1 ? (
                                        <Link key={breadcrumb.dirName} underline="hover" color="inherit"
                                              onClick={() => {
                                                  setParentIdForMoveResources(breadcrumb.resourceId)
                                                  setBreadcrumbsForMoveResources(breadcrumbsForMoveResources.slice(0, index + 1))
                                              }} component="button">
                                            {breadcrumb.dirName}
                                        </Link>
                                    ) : (<Typography key={breadcrumb.dirName}
                                                     sx={{color: 'text.primary'}}>{breadcrumb.dirName}</Typography>)
                                })}
                            </Breadcrumbs>
                            <List sx={{
                                '& .MuiListItemButton-root': {
                                    '.MuiButtonBase-root': {
                                        display: 'none',
                                    },
                                },
                                // hover states
                                '& .MuiListItemButton-root:hover': {
                                    '.MuiButtonBase-root': {
                                        display: 'block',
                                    },
                                },
                            }}>
                                {
                                    (resourcesDataForMovingFiles && breadcrumbsForMoveResources.length > 0) && (
                                        resourcesDataForMovingFiles.data
                                            .filter(el => el.resourceType === ResourceType.Dir && !selected.find(id => el.id === id))
                                            .map((resource) => {
                                                return (<ListItem disablePadding sx={{height: '50px'}}>
                                                    <ListItemButton onClick={() => {
                                                        setParentIdForMoveResources(resource.id.toString())
                                                        setBreadcrumbsForMoveResources([...breadcrumbsForMoveResources, {
                                                            resourceId: resource.id.toString(),
                                                            dirName: resource.name
                                                        }])
                                                    }}>
                                                        <ListItemIcon>
                                                            <FolderOutlinedIcon/>
                                                        </ListItemIcon>
                                                        <ListItemText primary={resource.name}/>
                                                        <Button onClick={() => {
                                                            moveResources(resource.id)
                                                        }}>Przenieś</Button>
                                                    </ListItemButton>
                                                </ListItem>)
                                            })
                                    )
                                }

                                {
                                    (breadcrumbsForMoveResources.length === 0) &&
                                    (<ListItem disablePadding sx={{height: '50px'}}>
                                        <ListItemButton onClick={() => {
                                            setParentIdForMoveResources(null)
                                            setBreadcrumbsForMoveResources([{
                                                resourceId: null,
                                                dirName: 'Katalog główny'
                                            }])
                                        }}>
                                            <ListItemIcon>
                                                <FolderOutlinedIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary={'Katalog glowny'}/>
                                            <Button onClick={() => {
                                                moveResources(null)
                                            }}>Przenieś</Button>
                                        </ListItemButton>
                                    </ListItem>)
                                }

                                {(resourcesDataForMovingFiles && resourcesDataForMovingFiles.data.filter(el => el.resourceType === ResourceType.Dir && !selected.find(id => el.id === id)).length === 0) && (
                                    <Typography
                                        sx={{color: 'text.primary', paddingY: '10px'}}>Brak folderów...</Typography>)
                                }
                            </List>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            setOpenMoveResourcesDialog(false)
                            setBreadcrumbsForMoveResources([])
                        }} autoFocus>
                            {t('common.cancel')}
                        </Button>
                    </DialogActions>
                </Dialog>)}

            </>)}
        </Box>
    )
}

export default MediaList;
