import React from 'react';
import logo from '../../images/logo-ams.jpg'
import './CmTopBar.scss'

const CmTopBar = () => {
    return (<div className={'CmTopBar'}>
        <div className={'CmTopBar__logo'}>
            <img
                height={'48px'}
                className='CmTopBar__logo--img'
                src={logo}
                alt='logo'
            />
        </div>
    </div>)
}

export default CmTopBar;