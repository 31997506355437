import React, {useEffect} from 'react';
import {useDispatch} from "react-redux";
import {setCredentials} from "./authSlice";
import {Navigate, Outlet, useLocation} from "react-router-dom";
import {store} from "../../store";
import { jwtDecode } from "jwt-decode";
import {UserBasicData} from "./types";

const RequireAuth = () => {
    const dispatch = useDispatch();
    const user = JSON.parse(localStorage.getItem('user')  || "{}") as UserBasicData || {};
    const token = Object.keys(user).length  && user.accessToken;
    if (token) {
        try {
            const decoded = jwtDecode(token);
        } catch (error) {
        }
    }
    // useEffect(() => {
    //     if (token && !store.getState().auth.accessToken) {
    //         dispatch(setCredentials(user))
    //     }
    // })
    const location = useLocation();

    return (token ? <Outlet/> : <Navigate to={'/login'} state={{from: location}} replace></Navigate>)
}

export default RequireAuth;